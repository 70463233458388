import React, { useState, Fragment } from "react";
import { Divider, Col, Row, Dropdown, Tooltip } from "antd";
import styles from "./css/WishlistResults.module.less";
import { FileFilled, MoreOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { parseQuery } from '../../basicSearch/service';
import { supplySearchActions } from '../../basicSearch/actions/supplySearch';
import { supplySearchActions as broswerSupp } from '../../browseSupply/actions/supplySearch'
import { useDispatch } from 'react-redux';
import { downloadWishlist } from '../service';
import WishListMenu from './WishListMenu';
import WishlistDeleteConfirmModal from './WishlistDeleteConfirmModal';
import WishlistRenameModal from './WishlistRenameModal';

export function WishListResults(props) {

  const { wishList, onRefresh, isLoading, setLoading } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [wishlistModalRename, setWishlistModalRename] = useState(false);
  const [currentWishlist, setCurrentWishlist] = useState({});

  const history = useHistory();
  const dispatch = useDispatch();

  const viewCreatedDate = (date) => {
    if (date) {
      return format(new Date(date), 'yyyy.MM.dd')
    }
  }

  const formatLastDate = (date) => {
    if (date) {
      return format(new Date(date), 'dd/MM/yyyy');
    }
  }

  const handleMenuClick = (e, propValue) => {
    setCurrentWishlist(() => propValue);
    switch (e.key) {
      case "2":
        const params = parseQuery(propValue?.searchUrl);
        if (params && params?.globalSearchType === 'BASIC') {
          dispatch({
            type: broswerSupp.SUPPLY_SEARCH_UPDATE_DISTANCE,
            payload: params.distance,
          });

          dispatch({
            type: broswerSupp.SUPPLY_SEARCH_UPDATE_DESTINATION,
            payload: {
              'city': params.city,
              'lat': params.latitude,
              'lan': params.longitude,
            }
          })
        }

        history.push('./basic-search', { ...propValue });
        break;
      case "3":
        setWishlistModalRename(true);
        break;
      case "4":
        setLoading(true);
        downloadXLS(propValue);
        break;
      case "5":
        setIsModalVisible(true);
        break;
      default:
        break;
    }
  }

  const downloadXLS = (propValue) => {
    if (propValue) {
      downloadWishlist(propValue.id).then(blob => {
        if (blob) {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${propValue.name}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoading(false);
        }
      })
    }
  }

  const menuOverLay = (propValue) => {
    const items = parse(propValue?.searchUrl);
    return (
      <Fragment>
        <WishListMenu onClick={handleMenuClick} menuItem={propValue} hide={items?.isError} />
      </Fragment>
    )
  }

  const objectParams = (result) => {
    let city, country = '';
    let cityRefs = [];
    const objParams = parse(result?.searchUrl);
    cityRefs = objParams?.city ? objParams?.city.split(",") : [];

    if (cityRefs && cityRefs.length > 0) {
      city = cityRefs[0];
      country = cityRefs[cityRefs.length - 1];
    }

    return setupLastSearchLbl(result, objParams, city, country);
  }

  const setupLastSearchLbl = (result, objParams, city, country) => {
    if (objParams && objParams.globalSearchType === 'ADVANCE') {
      return setupAdvancedSearchArr(result, objParams, city, country);
    } else {
      return setupBasicSearchArr(result, objParams, city, country);
    }
  }

  const setupAdvancedSearchArr = (result, objParams, city, country) => {
    let advancedSearchLbl = [];
    advancedSearchLbl.push(formatLastDate(result.updatedAt));
    if (city) {
      advancedSearchLbl.push(city);
    }

    if (city && objParams?.distance) {
      advancedSearchLbl.push(objParams?.distance);
    }

    if (objParams?.countryValue) {
      advancedSearchLbl.push(objParams?.countryValue);
    }

    return advancedSearchLbl.length > 1 ? advancedSearchLbl.join(", ").replaceAll(" ,", "") : advancedSearchLbl;
  }

  const setupBasicSearchArr = (result, objParams, city, country) => {
    let basicSearchLbl = [];
    basicSearchLbl.push(formatLastDate(result.updatedAt));
    if (city) {
      basicSearchLbl.push(city);
    }

    if (city && objParams?.distance) {
      basicSearchLbl.push(objParams?.distance);
    }

    if (country) {
      basicSearchLbl.push(country);
    }

    return basicSearchLbl.length > 1 ? basicSearchLbl.join(", ").replaceAll(" ,", "") : basicSearchLbl;
  }

  return (
    <div>
      <Col>
        <WishlistDeleteConfirmModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          wishlistProp={currentWishlist}
          onRefresh={onRefresh}
        />
      </Col>

      <Col>
        <WishlistRenameModal
          isWishlistModalVisible={wishlistModalRename}
          setWishlistModalRename={setWishlistModalRename}
          wishlistProp={currentWishlist}
          onRefresh={onRefresh}
        />
      </Col>
      <Row span={15}  >
        {(wishList && wishList.length > 0) && (
          wishList?.map((value, i) => (
            <Fragment>
              <Col key={i} span={10} className={styles.trWrap}>
                <Col className={styles.nr}>
                  <b>
                    {
                      value?.name?.toString()?.length > 16 && (
                        <Tooltip title={value?.name}>
                          <span style={{ fontSize: '14px' }}> {value?.name.toString().substring(0, 16) + "..."}</span>
                        </Tooltip>
                      )
                    }
                    {
                      value?.name?.length <= 16 && (
                        <span style={{ fontSize: '14px' }}>{value?.name}</span>
                      )
                    }
                  </b>
                </Col>
                <div className={styles.trp}>
                  <div className={styles.np} style={{ fontSize: '12px' }}>
                    Created {viewCreatedDate(value.createdAt)}
                  </div>
                  <div className={styles.np} style={{ fontSize: '12px' }}>
                    {value.propertyCount} properties
                  </div>
                  <div>
                    <div className={styles.nm} style={{ fontSize: '12px' }}>
                      {
                        objectParams(value)?.length > 61 && (
                          <Tooltip title={objectParams(value)}>
                            <span style={{ fontSize: '12px' }}> {objectParams(value)?.substring(0, 61) + "..."}</span>
                          </Tooltip>
                        )
                      }
                      {
                        objectParams(value)?.length <= 61 && (
                          <span style={{ fontSize: '12px' }}> {objectParams(value)}</span>
                        )
                      }
                    </div>
                  </div>
                  <div className={styles.adw}>
                    <FileFilled style={{ color: '#096DD9', cursor: "pointer" }} onClick={() => history.push(`/wishlist-prop`, { ...value })} />
                  </div>
                  <div style={{ paddingRight: '1rem' }}>
                    <Dropdown overlay={menuOverLay(value)}>
                      <MoreOutlined
                        style={{ color: '#096DD9' }}
                        className={styles.moreIcon}
                      />
                    </Dropdown>
                  </div>
                </div>
              </Col>
              <Divider />
            </Fragment>
          ))
        )}
      </Row>
    </div>
  );
}