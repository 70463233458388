import React, { Fragment, useEffect, useState } from "react";
import { Skeleton, Popover, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { browseSupplyActions } from "../actions";
import { store } from "../../../redux/store";
import styles from "./css/SearchedPropertyList.module.less";
import ImageViewer from './ImageViewer';
import { searchGlobalProperties, getAdvancedSearchServerUrl, getBasicSerachServerUrl, searchWishlist, searchFromQuery, searchWishlistProp } from '../service';
import star from '../../../assets/images/start.svg';
import { parse } from 'query-string';
import Wishlist from './Wishlist';
import { parseQuery } from '../service';
import { supplySearchActions } from '../actions/supplySearch';

import { useLocation, useHistory } from 'react-router-dom';
import { replace } from "lodash";

export const SearchedPropertyList = (props) => {

  const { state, pathname } = useLocation();

  const history = useHistory();
  const params = useSelector((state) => window.getValue(state, "searchparams"));
  const dispatch = useDispatch();

  const [hpage, setpage] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [hpagesize, setpageSize] = useState(10);
  const [resultvalue, setResultValue] = useState([]);
  const [loadspinner, setloadsppinner] = useState(false);
  const [pagin, setpagin] = useState(false);
  const [showftr, setftr] = useState(false);
  const [isNewSearch, setIsNewSearch] = useState(false);

  const searchParamsbasic = useSelector((state) =>
    state.searchParamsBasic
  );

  useEffect(() => {
    if (state && state?.searchUrl) {
      const url = state.searchUrl;
      history.push(pathname, replace(true));
      localStorage.removeItem("advancedSearch");
      setftr(false);
      fetchProperty(url);
    } else {
      setSearchProperties();
    }
  }, [searchParamsbasic, hpage, hpagesize]);

  function setSearchProperties() {
    setIsNewSearch(searchParamsbasic.isNewSearch);
    if (isNewSearch) {
      setpage(0);
      setPageOffset(0);
    }

    if (searchParamsbasic.globalSearchType === "ADVANCE") {
      const url = getAdvancedSearchServerUrl(searchParamsbasic, pageOffset, hpagesize);
      fetchPropertyData(url);
    }

    if (searchParamsbasic.globalSearchType === "BASIC" && searchParamsbasic?.destination && searchParamsbasic?.destination?.city) {
      const url = getBasicSerachServerUrl(searchParamsbasic, pageOffset, hpagesize);
      fetchPropertyData(url);
    }
  }

  function onPageChange(e) {
    if (e.target && e.target.value) {
      setpage(0)
      setIsNewSearch(false);
      setpageSize(+e.target.value);
      setPageOffset(0);
    }
  }

  const fetchProperty = (query) => {
    setloadsppinner(true);
    const params = parseQuery(query);
    if (params && params.globalSearchType === 'ADVANCE') {
      const advancedParams = {
        hotelName: params?.hotelName,
        address: params?.address,
        zip: params?.zip,
        city: {
          city: params?.city ? params?.city : '',
          lat: params?.cityLat ? params?.cityLat : '',
          lng: params?.cityLng ? params?.cityLng : ''
        },
        distance: params?.distance,
        country: {
          value: params?.countryValue ? params?.countryValue : '',
          code: params?.countryCode ? params?.countryCode : '',
          longitude: params?.countryLongitude ? params?.countryLongitude : '',
          latitude: params?.countryLatitude ? params?.countryLatitude : ''
        },
        isNewSearch: true,
        globalSearchType: params?.globalSearchType
      }
      localStorage.setItem("advancedSearch", JSON.stringify(advancedParams));

      dispatch({
        type: supplySearchActions.SUPPLY_SEARCH_UPDATE_BASIC_REDUX_STATE_ALL,
        payload: advancedParams
      });
      setloadsppinner(false);
    } else {
      let values = {
        distance: params.distance,
        lat: params.latitude,
        lng: params.longitude,
        isNewSearch: true,
        city: params.city,
        destination: {
          city: params.city,
          lat: params.latitude,
          lng: params.longitude,
        }
      }

      dispatch({
        type: supplySearchActions.SUPPLY_SEARCH_UPDATE_BASIC_SETTING_REDUX_STATE,
        payload: values,
      });
      setloadsppinner(false);
    }
  }

  function fetchPropertyData(url) {
    if (url) {
      setloadsppinner(true);
      setftr(false);

      searchGlobalProperties(url).then(response => {
        if (response) {
          store.dispatch({
            type: browseSupplyActions.BROWSE_SUPPLY_SEARCH_LOADING,
            payload: false,
          });

          response.result.hasNext ? setpagin(true) : setpagin(false);
          setResultValue(response.result.result);

          if (response.success) {
            setftr(true)
          }

        }
        setloadsppinner(false);
      }).catch((e) => {
        console.log(e);
        setloadsppinner(false);
      });
    }
  }

  const onFetch = (fetch) => {
    if (fetch) {

      if (searchParamsbasic.globalSearchType === "ADVANCE") {
        const url = getAdvancedSearchServerUrl(searchParamsbasic, pageOffset, hpagesize);
        searchGlobalProperties(url).then(response => {
          if (response) {
            store.dispatch({
              type: browseSupplyActions.BROWSE_SUPPLY_SEARCH_LOADING,
              payload: false,
            });

            response.result.hasNext ? setpagin(true) : setpagin(false);
            setResultValue(response.result.result);
          }
        })
      }

      if (searchParamsbasic.globalSearchType === "BASIC" && params?.destination && params?.destination?.city) {
        const url = getBasicSerachServerUrl(searchParamsbasic, pageOffset, hpagesize);
        searchGlobalProperties(url).then(response => {
          if (response) {
            store.dispatch({
              type: browseSupplyActions.BROWSE_SUPPLY_SEARCH_LOADING,
              payload: false,
            });

            response.result.hasNext ? setpagin(true) : setpagin(false);
            setResultValue(response.result.result);
          }
        })
      }
    }
  }

  return (
    <Fragment>
      {
        (loadspinner)
          ? <Skeleton loading={loadspinner} active></Skeleton>
          :
          <div className={styles.propertyContainer}>
            {
              resultvalue && resultvalue.length > 0 && (
                resultvalue.map(function (item, index) {
                  const { id, name, address, city, country, zip, knownIds } = item;
                  let supplyName = [];
                  let imgLink = [];
                  let rateCount = 0;

                  return (
                    <div key={index}>
                      {
                        knownIds?.map(function (subitem) {
                          supplyName.unshift(subitem.idScheme.split(','));
                          if (subitem?.property && subitem?.property?.info && subitem?.property?.info?.images) {
                            imgLink.unshift(subitem.property.info.images);
                          }
                          if (subitem?.property && subitem?.property?.info && subitem?.property?.info?.award) {
                            rateCount = subitem.property.info.award.value
                          }
                        })
                      }
                      <div key={id} className={styles.trWrap}>
                        <div className={styles.imgwrap}>
                          <ImageViewer dataItem={item} />
                        </div>
                        <div className={styles.trp}>
                          <div className={styles.nm}>
                            <div>
                              {
                                name?.toString()?.length > 59 && (
                                  <Tooltip placement={'top'} title={name}>
                                    <span className={styles.nameFont}>{name.toString().substring(0, 58) + "..."}</span>
                                  </Tooltip>
                                )
                              }
                              {
                                name.length <= 59 && (
                                  <span className={styles.nameFont}>{name}</span>
                                )
                              }
                            </div>
                            <div className={styles.adr} style={{ fontSize: '14px' }}>
                              {address}, {city}, {country} {zip}
                            </div>
                          </div>
                          <div>
                            <span className={styles.sr} style={{ fontSize: '12px' }}>Start Ratings</span>  <br />
                            <div className={styles.adr} style={{ fontSize: '14px' }}>
                              {
                                rateCount > 0 && (
                                  <span>
                                    {[...Array(rateCount)].map((element, index) => {
                                      return <img key={index} src={star} alt={element} />
                                    })}
                                  </span>)
                              }
                              {
                                rateCount === 0 && 'N/A'
                              }
                            </div>
                          </div>
                          <div className={styles.adw}>
                            <span className={styles.sr} style={{ fontSize: '12px' }}>Suppliers</span>
                            <span className={styles.adr} style={{ fontSize: '14px' }}>
                              {
                                supplyName?.length > 0 && (
                                  <span>
                                    {
                                      supplyName[0]?.toString()?.length > 6 && (
                                        <Tooltip title={supplyName[0]}>
                                          <span> {supplyName[0].toString().substring(0, 6) + "..."}</span>
                                        </Tooltip>
                                      )
                                    }
                                    {
                                      supplyName[0]?.toString()?.length <= 6 && (
                                        <span> {supplyName[0].toString()}</span>
                                      )
                                    }
                                    {
                                      item.knownIds.length >= 2 && ", "
                                    }
                                    {
                                      supplyName[1]?.toString()?.length > 6 && (
                                        <Tooltip title={supplyName[1]}>
                                          <span> {supplyName[1].toString().substring(0, 6) + "..."}</span>
                                        </Tooltip>
                                      )
                                    }
                                    {
                                      supplyName[1]?.toString()?.length <= 6 && (
                                        <span> {supplyName[1].toString()}</span>
                                      )
                                    }
                                  </span>
                                )
                              }
                              {
                                item.knownIds.length > 2 ? (
                                  <span> +{item.knownIds.length - 2}
                                    <Popover className={styles.moreOption} content={
                                      supplyName.map((supplier, index) =>
                                        <div key={index} className={styles.supplierListItem}>➡️ {supplier}</div>
                                      )
                                    } title="View Suppliers">
                                      <span >more</span>
                                    </Popover></span>) : null
                              }
                            </span>
                          </div>
                          <div>
                            {
                              +process.env.REACT_APP_SHOW_WISHLIST === 1 && (
                                <Wishlist name={name} id={id} searchedProp={item} onFetch={onFetch} />
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }))
            }

            {
              (resultvalue && resultvalue.length === 0) && (
                <div className={styles.noRecords}>
                  <h3>No search results</h3>
                </div>
              )
            }
          </div>
      }

      {
        showftr
          ?
          <div className={styles.pageWrap}>
            <div className={styles.selectWrap}>
              <span>Rows Per Page</span>
              <select
                onChange={onPageChange}
                value={hpagesize}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className={styles.pageNum}>
              <button
                disabled={hpage < 1}
                onClick={() => {
                  setpage(hpage - 1);
                  setPageOffset(pageOffset - hpagesize);
                  setIsNewSearch(false);
                }}
              >
                <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg>
              </button>
              <div className={styles.textNum}>
                {hpage + 1}
              </div>
              <button
                disabled={!pagin}
                onClick={() => {
                  setpage(hpage + 1);
                  setPageOffset(pageOffset + resultvalue.length);
                  setIsNewSearch(false);
                }}
              >
                <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg>
              </button>
            </div>
          </div>
          : ''
      }
    </Fragment>
  );
}
