export const userActions = {
  FETCH_USER_LOADING: "FETCH_USER_LOADING",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "FETCH_USER_FAILURE",

  FETCH_USER_MANAGEMENT_LIST_LOADING: "FETCH_USER_MANAGEMENT_LIST_LOADING",
  FETCH_USER_MANAGEMENT_LIST_SUCCESS: "FETCH_USER_MANAGEMENT_LIST_SUCCESS",
  FETCH_USER_MANAGEMENT_LIST_FAILURE: "FETCH_USER_MANAGEMENT_LIST_FAILURE",

  SET_USER_DETAILS: "SET_USER_DETAILS",
};
