import React from 'react';
import { Country as countryRef } from 'country-state-city';
import { useIntl } from "react-intl";
import { AutoComplete } from "antd";

const Country = (props) => {
  const { value, id } = props;
  const intl = useIntl();
  const countries = countryRef.getAllCountries().map(x => {
    return {
      value: x.name,
      code: x.isoCode,
      longitude: x.longitude,
      latitude: x.latitude
    }
  }).sort((a, b) => {
    const countryValueA = a.value.toUpperCase();
    const countryValueB = b.value.toUpperCase();
    if (countryValueA < countryValueB) {
      return -1;
    }
    if (countryValueA > countryValueB) {
      return 1;
    }
    return 0;
  });
  return (
    <AutoComplete
      options={countries}
      value={value?.value}
      placeholder={intl.formatMessage({ id: id ? id : "nemo.country" })}
      allowClear={true}
      onSelect={(value, option) => props?.onSelect({ value, ...option })}
      onChange={(value, option) => props?.onChange({ value, ...option })}
      filterOption={(inputValue, option) =>
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
    />
  )
}

export default Country;