import React, { Fragment, Component } from "react";
import { Row, Col, Input, Button, Divider, Spin, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import WishListFilters from "./WishListFilters";
import { WishListResults } from "./WishListResults";
import { WishlistHeader } from "./WishlistHeader";
import styles from './css/WishlistHeader.module.less';
import { searchWishlist } from '../service';
import WishlistPagination from './WishlistPagination';

const { Search } = Input;

class WishListPage extends Component {

  // INITIAL STATE
  state = {
    wishList: [],
    isLoading: false,
    term: '',
    page: 0,
    size: 10,
    fromDate: '',
    toDate: '',
    minProperty: '',
    maxProperty: '',
    totalPages: 0,
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.fetchWishList();
  }

  fetchWishList = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isLoading: true
      }
    }, () => {
      searchWishlist(this.state).then(serviceRes => {
        if (serviceRes && serviceRes.data) {
          const totalPages = Math.floor((serviceRes?.data?.total + this.state.size - 1) / this.state.size)
          this.setState((prevState) => {
            return {
              ...prevState,
              wishList: serviceRes?.data?.result,
              totalPages: totalPages
            }
          });
        }

        if (serviceRes && serviceRes.error) {
          message.error(serviceRes.error.message);
        }
        this.setState((prevState) => {
          return {
            ...prevState,
            isLoading: false
          }
        });
      });
    });
  }

  onRefresh = (result) => {
    if (result) {
      this.fetchWishList();
    }
  }

  onSearchTrigger = (e) => {
    if (this.state.term) {
      this.setState((prevState) => {
        return {
          ...prevState,
          term: this.state.term
        }
      }, () => {
        this.fetchWishList();
      });
    } else {
      this.fetchWishList();
    }
  }

  onFiltrationHandler = (params) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        page: 0,
        size: 10,
        fromDate: params?.fromDate,
        toDate: params?.toDate,
        minProperty: params?.minProperty,
        maxProperty: params?.maxProperty
      }
    }, () => {
      this.fetchWishList();
    });
  }

  onSearchChangeHandler = (event) => {
    const term = event.target.value;
    if (term) {
      this.setState((prevState) => {
        return {
          ...prevState,
          term: term,
          page: 0,
          size: 10,
        }
      });
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          term: null,
          page: 0,
          size: 10,
        }
      }, () => {
        this.fetchWishList();
      });
    }
  }

  moveNext = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        page: prevState.page + 1,
        size: prevState.size,
      }
    }, () => {
      this.fetchWishList();
    });
  }

  movePrev = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        page: prevState.page - 1,
        size: prevState.size,
      }
    }, () => {
      this.fetchWishList();
    });
  }

  pageOnChange = (value) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        size: +value,
        page: 0
      }
    }, () => {
      this.fetchWishList();
    });
  }

  setLoading = (loading) => {
    this.setState({
      isLoading: loading
    })
  }

  render() {
    return (
      <div>
        <Fragment>
          <Row className={styles.bsHeader}>
            <WishlistHeader />
          </Row>
          <Row justify="space-between">
            <Col span="6">
              <Search
                data-testid={'search-test-id'}
                allowClear
                enterButton={
                  <Button
                    icon={<SearchOutlined onClick={() => this.onSearchTrigger()} />}
                  />}
                placeholder={'Search'}
                onChange={this.onSearchChangeHandler}
                onPressEnter={this.onSearchTrigger}
              />
            </Col>
            <Col className={styles.bsHeader}>
              <WishListFilters onFiltrationHandler={this.onFiltrationHandler} onRefresh={this.onRefresh} />
            </Col>
          </Row>
          <Divider />
          {
            (this.state.wishList && this.state.wishList.length > 0) && (
              <Row>
                <WishListResults isLoading={this.state.isLoading} wishList={this.state.wishList} onRefresh={this.onRefresh} setLoading={this.setLoading} />
              </Row>
            )
          }

          {
            (!this.state.isLoading && this.state.wishList && this.state.wishList.length === 0) && (
              <Row style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center', paddingTop: 10 }}>
                <h3 style={{ textAlign: 'center' }}>No search results</h3>
              </Row>
            )
          }

          {
            this.state.isLoading && (
              <div className={styles.spinWrapper}>
                <Spin size="large" />
              </div>
            )
          }

          {
            (!this.state.isLoading && this.state.wishList) && (
              <WishlistPagination
                queryParams={this.state}
                pageSize={this.state.size}
                moveNext={this.moveNext}
                movePrev={this.movePrev}
                onPageChange={this.pageOnChange}
              />
            )
          }
        </Fragment>
      </div>
    )
  }
}

export default WishListPage;