import React, { useState, useEffect } from 'react';
import { Input, Modal, message } from 'antd';
import styles from './css/WishlistFilters.module.less';

import { patchWishlist } from '../service';

const WishlistRenameModal = (props) => {

    const { isWishlistModalVisible, setWishlistModalRename, wishlistProp, onRefresh } = props;

    const [fieldRename, setFieldRename] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [errorMsg, setErrorMsg] = useState();

    useEffect(() => {
        return () => {
            setFieldRename('');
            setDisabled(false);
            setErrorMsg('');
        }
    }, [])

    const handleOk = () => {
        const payload = JSON.parse(JSON.stringify(wishlistProp));
        payload.name = fieldRename;
        setDisabled(true);

        patchWishlist(payload).then(serviceRes => {
            if (serviceRes && serviceRes.data) {
                setFieldRename('');
                setErrorMsg('');
                onRefresh(true);
                setWishlistModalRename(false);
            }

            if (serviceRes && serviceRes.error) {
                setErrorMsg(serviceRes.error.message);
            }
            const timeoutRef = setTimeout(() => {
                setDisabled(false);
                clearTimeout(timeoutRef);
            }, 1000);
        }).catch(error => {
            console.log(error);
        })
    }

    const handleCancel = () => {
        setWishlistModalRename(false);
    }

    const onValueChange = (event) => {
        const fieldName = event.target.value;
        if (!fieldName) {
            setErrorMsg("");
        }

        if (fieldName && fieldName.length >= 64) {
            setDisabled(true);
            setErrorMsg("The value entered can have at most 64 characters");
        }

        if (fieldName && fieldName.length < 64) {
            setErrorMsg("");
            setDisabled(false);
        }

        setFieldRename(fieldName)
    }

    const afterClose = () => {
        setErrorMsg('');
    }

    return (
        <Modal title="Rename wishlist"
            visible={isWishlistModalVisible}
            afterClose={afterClose}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: disabled }}>
            <div>
                <h4>"{wishlistProp?.name}." will be renamed to</h4>
                <Input
                    placeholder="New name"
                    value={fieldRename}
                    onChange={(event) => onValueChange(event)}
                    className={(errorMsg) ? styles.txtErrValidation : ''}
                />
                {errorMsg && (<span className={styles.toastError}>{errorMsg}</span>)}
            </div>
        </Modal>
    )
}

export default WishlistRenameModal