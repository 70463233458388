import React, { useCallback, useState, useEffect } from "react";
import { Menu, Dropdown, Divider } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { Link, useRouteMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { offerListSearchAndFilterActions } from "../../pages/offers/actions";
import { useDispatch } from "react-redux";
import styles from './css/Tabs.module.less';
import { DownOutlined, UserOutlined, ShopOutlined } from '@ant-design/icons';

function Tabs() {
  const dispatch = useDispatch();
  const isBrowseSupplyPage = useRouteMatch({
    path: "/browse-supply/:page?",
    strict: true,
    sensitive: true,
  });

  const [currentState, setCurrentState] = useState(
    isBrowseSupplyPage ? "browse-supply" : "offers"
  );

  useEffect(() => {
    const tabKey = JSON.parse(localStorage.getItem('tab-key'));
    if (tabKey) {
      setCurrentState(tabKey);
    } else {
      setCurrentState(isBrowseSupplyPage ? "browse-supply" : "offers")
    }
  }, [isBrowseSupplyPage]);


  const handleClick = useCallback((e) => {
    localStorage.setItem('tab-key', JSON.stringify(e.key))
    setCurrentState(e.key);
    dispatch({
      type: offerListSearchAndFilterActions.RESET_OFFERS_PAGINATION
    });
  }, [dispatch]);
  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="1" >
        <Link to="/basic-search/">
          <FormattedMessage id="nemo.allproperties" />
        </Link>
      </Menu.Item>
      <Menu.Item key="2" >
        <Link to="/browse-supply/" data-testid="browse-supply">
          <FormattedMessage id="nemo.properties.with.pricing" />
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Menu
      onClick={handleClick}
      defaultSelectedKeys={[currentState]}
      selectedKeys={[currentState]}
      mode="horizontal">
      <Menu.Item
        data-testid="offer-tab"
        key="offers"
        icon={<PlusSquareOutlined className={styles.iconSize} />}>
        <Link to="/offers">
          <FormattedMessage id="nemo.offers" />
        </Link>
      </Menu.Item>
      <Menu.Item
        icon={<ShopOutlined className={styles.iconSize} />}>
        <Dropdown
          overlay={menu}  >
          <a className={"ant-dropdown-link " + styles.antDownIcon} onClick={e => e.preventDefault()}>
            <FormattedMessage id="nemo.search.space" />
            <FormattedMessage id="nemo.properties" />
            <FormattedMessage id="nemo.search.space" />
            <DownOutlined />
          </a>
        </Dropdown>
      </Menu.Item>
    </Menu>
  );
}

export default Tabs;
