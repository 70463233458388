import React, { useState, useEffect, Fragment } from 'react';
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { Col, Row, Input, Button, Divider, Spin } from 'antd';

import styles from './css/WishlistLastSearch.module.less';
import WishlistSearchItem from './WishlistSearchItem';
import { searchWishlistProp } from '../service';

const WishlistSearchProperties = (props) => {

    const { state } = useLocation();
    const [properties, setProperties] = useState([]);
    const [searchTerm, setSearchTerm] = useState();
    const [tempProperties, setTempProperties] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    let history = useHistory();

    const { Search } = Input;

    useEffect(() => {
        setIsLoading(true);
        fetchLastSearchProperty();
        return () => {
            setProperties([]);
            setSearchTerm();
            setTempProperties([]);
            setIsLoading(false);
        }
    }, []);

    const fetchLastSearchProperty = () => {
        setProperties([]);
        searchWishlistProp(state?.id).then(serviceRes => {
            if (serviceRes && serviceRes.data) {
                setTempProperties(serviceRes.data);
                setProperties(serviceRes.data);
                setIsLoading(false);
            }
        })
    }

    const onPrevHandler = () => {
        history.goBack();
    }

    const onRefresh = () => {
        setIsLoading(true)
        fetchLastSearchProperty()
    }
    const onPressEnterHandler = () => {
        if (searchTerm && tempProperties.length > 0) {
            const filteredProps = tempProperties.filter(x =>
                x?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                x?.address?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                x?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                x?.country?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                x?.zip?.includes(searchTerm) ||
                x?.knownIds?.some(y => y.idScheme?.toLowerCase().includes(searchTerm?.toLowerCase()))
            );
            setProperties(() => filteredProps);
        } else {
            setProperties(tempProperties);
        }
    }

    const onSearchHandler = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (!term) {
            setProperties(tempProperties);
        }
    }

    return (
        <Fragment>
            <Col span={24}>
                <div className={styles.headSection}>
                    <div className={styles.propSection}>
                        <ArrowLeftOutlined className={styles.arrowHead} onClick={onPrevHandler} />
                        <div style={{ paddingTop: 6, paddingLeft: 8 }}>
                            <h4 className={styles.mainHeader}>{state?.name}</h4>
                        </div>
                    </div>
                </div>
            </Col>
            <Row justify="space-between" style={{ marginTop: 10 }}>
                <Col span="6">
                    <Search
                        allowClear
                        enterButton={
                            <Button
                                icon={<SearchOutlined onClick={() => onPressEnterHandler()} />}
                                onClick={() => onPressEnterHandler()}
                            />}
                        placeholder={'Search'}
                        onChange={onSearchHandler}
                        onPressEnter={onPressEnterHandler}
                    />
                </Col>
            </Row>
            <Divider style={{ marginTop: 12 }} />
            {
                !isLoading && (
                    properties.map((value, index) => {
                        const suppliers = value.knownIds.map(x => x.idScheme);
                        const rateCount = value.knownIds.find(x => x.property?.info?.award?.value);
                        value['suppliers'] = suppliers;
                        value['rateCount'] = rateCount ? rateCount : 0;

                        return (
                            <Fragment key={index}>
                                <div key={index}>
                                    <WishlistSearchItem onRefresh={onRefresh} selectedWishlist={state} key={index} property={value} />
                                </div>
                                <Divider style={{ marginTop: 12 }} />
                            </Fragment>
                        )
                    })
                )
            }
            {
                (!isLoading && properties.length === 0) && (
                    <Fragment>
                        <div className={styles.emptyWrapper}>
                            <Row className={styles.textWrapper}>
                                <h3 className={styles.emptyText}>Wishlist is empty</h3>
                            </Row>
                            <a className={styles.searchText} onClick={() => history.push('/basic-search')}>Go to search</a>
                        </div>
                        <Divider style={{ marginTop: '96px' }} />
                    </Fragment>
                )
            }
            {
                isLoading && (
                    <div className={styles.spinWrapper}>
                        <Spin size="large" />
                    </div>
                )
            }
        </Fragment>
    )
}

export default WishlistSearchProperties;
