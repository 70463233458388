import { baseURL } from "../../helpers";
import { stringify } from 'query-string';
import { SearchParams } from './components/search-param.service';

export const searchWishlist = (params) => {
    if (params) {
        const param = {
            name: params.term,
            fromDate: params.fromDate,
            toDate: params.toDate,
            minPropertyCount: params.minProperty,
            maxPropertyCount: params.maxProperty,
            page: params.page,
            size: params.size
        }
        const paramObject = SearchParams(param);
        const queryString = stringify(paramObject);
        return window.getWrapper(`${baseURL.API_URL_BASE}wishlists?${queryString}`);
    } else {
        return window.getWrapper(`${baseURL.API_URL_BASE}wishlists`);
    }
}

export const getWishlist = (params) => {
        const param = {
            page: params.page,
            size: params.size
        }
        const paramObject = SearchParams(param);
        const queryString = stringify(paramObject);
        return window.getWrapper(`${baseURL.API_URL_BASE}wishlists?${queryString}`);
}

export const searchWishlistProp = (id) => {
    return window.fetchWrapper(`${baseURL.API_URL_BASE}wishlists/${id}/properties`);
}

export const deleteWishlist = (id) => {
    return window.deleteWrapper(`${baseURL.API_URL_BASE}wishlists/${id}`);
}

export const downloadWishlist = (id) => {
    return window.downloadWrapper(`${baseURL.API_URL_BASE}wishlists/download/${id}`);
}

export const patchWishlist = (wishlistPayload) => {
    return window.patchWrapper(`${baseURL.API_URL_BASE}wishlists/${wishlistPayload.id}`, JSON.stringify(wishlistPayload));
}

export const propertySearch = (searchUrl) => {
    return window.fetchWrapper(searchUrl);
}

export const removePropertyFromWishlist = (payload) => {
    return window.putWrapper(`${baseURL.API_URL_BASE}wishlists/properties`, JSON.stringify(payload));
}

export const movePropertyFromWishlist = (payload) => {
    return window.putWrapper(`${baseURL.API_URL_BASE}wishlists/properties/transfer`, JSON.stringify(payload));
}