import React, { Component } from 'react';
import { Popover, Checkbox, Input, Button, Tooltip } from 'antd';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { SearchOutlined } from "@ant-design/icons";

import { stringify, parse } from 'query-string';
import { Spin } from 'antd';
import styles from "./css/SearchedPropertyList.module.less";
import { checkAndAssignWishlist } from '../service';
import { baseURL } from '../../../helpers';

import InfiniteScroll from "react-infinite-scroll-component";

const { Search } = Input;

class Wishlist extends Component {

    constructor(props) {
        super(props);

        this.state = {
            wishValue: '',
            searchTerm: '',
            createdNewWish: '',
            visible: false,
            errorMsg: '',
            wishListData: [],
            totalPages: 0,
            hasMore: true,
            page: 0,
            pageSize: 10,
            wishlistCount: 0,
            currentWishCount: 0,
            loading: false
        }
    }

    componentDidMount() {
        this.setState({
            wishlistCount: this.props?.searchedProp?.wishlistCount,
        })
    }

    componentWillUnmount() {
    }

    onNewWishChecked = (event) => {
        this.setState({
            createdNewWish: event.target.checked
        });
    }

    onWishChange = (wish, event) => {
        this.state.wishListData.forEach(wd => {
            if (wd.id === wish.id) {
                wd.checked = event.target.checked;
            }
            return {
                ...wd
            }
        });

        this.setState((prevState) => {
            return {
                wishListData: this.state.wishListData,
                currentWishCount: event.target.checked ? (prevState.currentWishCount + 1) : (prevState.currentWishCount - 1)
            }
        });
    }

    searchOnChange = (event) => {
        this.setState({
            searchTerm: event?.target?.value
        })
        if (!event?.target?.value) {
            this.setState({
                page: 0,
                totalPages: 0,
                wishListData: [],
            }, () => {
                this.fetchWishlistData();
            });

        }
    }

    clearData = () => {
        this.setState({
            page: 0,
            totalPages: 0,
            searchTerm: null
        });
    }

    setupWishCount = () => {
        this.setState({
            wishlistCount: this.state.currentWishCount + (this.state.createdNewWish ? 1 : 0),
            wishValue: '',
            createdNewWish: ''
        })
    }

    onWishValueChange = (event) => {
        if (event?.target?.value?.length > 0) {
            this.setState({
                createdNewWish: true
            })
        } else {
            this.setState({
                createdNewWish: false
            })
        }

        const isExists = this.state?.wishListData?.some(x => x.name === event?.target?.value);
        if (isExists) {
            this.setState({
                errorMsg: 'Wishlist name already exist'
            });
        } else if (event?.target?.value?.length > 64) {
            this.setState({
                errorMsg: 'The value entered can have at most 64 characters'
            });
        } else {
            this.setState({
                errorMsg: ''
            });
        }

        this.setState({
            wishValue: event?.target?.value
        });
    }

    onVisibilityChange = (event) => {
        if (!event) {
            this.setState({
                searchTerm: null,
                visible: false,
                errorMsg: '',
                wishValue: '',
                createdNewWish: '',
                wishListData: [],
                page: 0
            });
        }
    }

    fetchData = (queryString, ignoreSearch = true) => {
        window.fetchWrapper(`${baseURL.API_URL_BASE}wishlists?${queryString}`).then(serviceRes => {
            if (serviceRes && serviceRes.data && serviceRes.data.result) {
                const totalPages = Math.floor((this.state?.wishListData?.total + this.state?.pageSize - 1) / this.state?.pageSize);

                this.setState((prevState) => {
                    return {
                        totalPages: totalPages,
                        hasMore: true,
                        wishListData: [...prevState.wishListData, ...serviceRes.data.result],
                        loading: false,
                    }
                })
            }
        });
    }

    fetchWishlistData = (ignoreSearch = true) => {
        let queryString = "";
        this.setState({
            loading: true
        })
        if (this.state.searchTerm) {
            queryString = stringify({ name: this.state.searchTerm, page: this.state.page, phonebookId: this.props.id, size: this.state.pageSize });
        } else {
            queryString = stringify({ page: this.state.page, phonebookId: this.props.id, size: this.state.pageSize });
        }

        this.setState((prevState) => {
            return {
                page: prevState.page + 1
            }
        })

        if (this.state.page != 0 && this.state.page === this.state.totalPages) {
            this.setState({
                hasMore: false
            })
        } else {
            this.fetchData(queryString, ignoreSearch);
        }
    }

    getSearchTerm = () => {
        const basicSearch = JSON.parse(localStorage.getItem('basicSearch'))?.destination;
        const advancedSearch = JSON.parse(localStorage.getItem('advancedSearch'));
        const searchUrl = localStorage.getItem('search-url');
        const items = parse(searchUrl);

        if (basicSearch) {
            items['city'] = basicSearch.city;
            items['latitude'] = basicSearch.lat;
            items['longitude'] = basicSearch.lng;
        }

        if (advancedSearch) {

            const { city, country } = advancedSearch;

            items['city'] = city?.city;
            items['cityLat'] = city?.lat;
            items['cityLng'] = city?.lng;

            items['countryValue'] = country?.value;
            items['countryCode'] = country?.code
            items['countryLongitude'] = country?.longitude
            items['countryLatitude'] = country?.latitude
        }

        return stringify(items);
    }

    onConfirmation = () => {
        this.setState({
            errorMsg: ''
        })
        const searchUrl = this.getSearchTerm();
        let wishlistSet = [];
        this.state.wishListData.forEach(x => {

            const wishProp = x?.properties?.filter(p => p.phonebookId === this.props.id);

            if (x && x.checked) {
                wishlistSet.push({
                    id: x.id,
                    name: x.name,
                    checked: x.checked,
                    properties: [
                        {
                            name: this.props.name,
                            phonebookId: this.props.id,
                            wishListPropertyStatus: wishProp.length > 0 ? 'ACTIVE' : 'NEW'
                        }
                    ],
                    searchUrl: searchUrl
                });
            } else if (wishProp.length > 0) {
                wishlistSet.push({
                    id: x.id,
                    name: x.name,
                    checked: x.checked,
                    properties: [
                        {
                            name: this.props.name,
                            phonebookId: this.props.id,
                            wishListPropertyStatus: 'DELETE'
                        }
                    ],
                    searchUrl: searchUrl
                });
            }
        });

        if (this.state.wishValue && this.state.wishValue.length > 0) {
            const newWishPayload = {
                name: this.state.wishValue,
                checked: this.state.createdNewWish ? true : false,
                properties: this.state.createdNewWish ? [
                    {
                        name: this.props.name,
                        phonebookId: this.props.id,
                        wishListPropertyStatus: 'NEW'
                    }
                ] : [],
                searchUrl: searchUrl
            }
            wishlistSet.push(newWishPayload);
        }
        let payload = {
            wishlists: wishlistSet
        };
        this.proceedSave(wishlistSet, payload);
    }

    proceedSave = (wishlistSet, payload) => {
        if (wishlistSet && wishlistSet.length > 0) {
            checkAndAssignWishlist(payload).then(serviceRes => {
                if (serviceRes && serviceRes.data) {
                    this.setState((prevState) => {
                        return {
                            visible: !prevState.visible,
                            searchTerm: null,
                            hasMore: true,
                            page: 0,
                            wishlistCount: serviceRes?.data?.wishlistCount
                        }
                    }, () => {
                        this.setupWishCount();
                        this.clearData();
                    });
                }

                if (serviceRes && serviceRes.error) {
                    this.setState({
                        errorMsg: serviceRes.error.message
                    })
                }
            });
        }
    }

    fetchFreshData = () => {
        const queryString = stringify({ page: 0, phonebookId: this.props.id, size: this.state.pageSize });
        window.fetchWrapper(`${baseURL.API_URL_BASE}wishlists?${queryString}`).then(serviceRes => {
            if (serviceRes && serviceRes.data && serviceRes.data.result) {
                const totalPages = Math.floor((this.state?.wishListData?.total + this.state?.pageSize - 1) / this.state?.pageSize);

                this.setState(() => {
                    return {
                        totalPages: totalPages,
                        hasMore: true,
                        wishListData: [...serviceRes.data.result],
                        loading: false,
                    }
                }, () => {
                    this.setupWishCount();
                })
            }
        });
    }

    onSearchEnterHandling = () => {
        this.setState({
            page: 0,
            wishListData: [],
        }, () => {
            if (this.state.searchTerm) {
                this.fetchWishlistData()
            } else {
                this.fetchFreshData();
            }
        });
    }

    wishListPopoverContent = () => {
        return (
            <div>
                <div style={{ padding: '.5rem' }}>
                    <Search
                        allowClear
                        value={this.state.searchTerm}
                        enterButton={
                            <Button
                                icon={<SearchOutlined onClick={this.onSearchEnterHandling} />}
                                onClick={() => this.onSearchEnterHandling()}
                            />}
                        placeholder={'Search'}
                        onChange={this.searchOnChange}
                        onPressEnter={this.onSearchEnterHandling}
                    />
                </div>

                <InfiniteScroll
                    dataLength={this.state.wishListData.length}
                    next={this.fetchWishlistData}
                    hasMore={this.state?.hasMore}
                    height={'110.5px'}
                >
                    {
                        (this.state.wishListData && this.state.wishListData.length > 0) && (
                            this.state.wishListData.map((wl, i) => (
                                <div key={i} className={styles.actionContainer}>
                                    <Checkbox key={i} checked={wl?.checked} onChange={(event) => this.onWishChange(wl, event)}>
                                        {
                                            wl?.name?.toString()?.length > 16 && (
                                                <Tooltip placement={'top'} title={wl?.name}>
                                                    <span> {wl?.name.toString().substring(0, 16) + "..."}</span>
                                                </Tooltip>
                                            )
                                        }
                                        {
                                            wl?.name.length <= 16 && (
                                                wl?.name
                                            )
                                        }
                                    </Checkbox>
                                </div>
                            ))
                        )
                    }
                    {
                        (this.state.wishListData.length === 0 && this.state.loading) && (
                            (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '40px' }}>
                                    <Spin />
                                </div>
                            )
                        )
                    }
                </InfiniteScroll>

                <div style={{ marginTop: '4px', marginBottom: '2px', paddingLeft: '.5rem', paddingRight: '.5rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Checkbox disabled={!this.state.wishValue} onChange={this.onNewWishChecked} checked={this.state.createdNewWish}></Checkbox>
                        <Input
                            placeholder="New wishlist"
                            size='middle'
                            className={(this.state.errorMsg) ? styles.txtErrValidation : ''}
                            style={{ marginLeft: '10px', width: '208px' }}
                            value={this.state.wishValue}
                            onChange={(value) => this.onWishValueChange(value)}
                            required
                        />
                    </div>
                    {this.state.errorMsg && (<span className={styles.toastError}>{this.state.errorMsg}</span>)}
                </div>

                <div className={styles.btnContainer}>
                    <button disabled={this.state?.wishValue?.length > 64} className={styles.btn} onClick={this.onConfirmation}>
                        Confirm
                    </button>
                </div>
            </div>
        )
    }

    onWishlistClick = () => {
        this.setState({
            visible: !this.state.visible,
            currentWishCount: this.state.wishlistCount,
            wishlistCount: this.state.wishlistCount,
            searchTerm: null,
            page: 0,
            wishListData: [],
        }, () => {
            this.fetchWishlistData();
        })
    }

    render() {
        return (
            <>
                <Popover
                    content={this.wishListPopoverContent}
                    trigger="click"
                    placement='bottom'
                    visible={this.state.visible}
                    onVisibleChange={this.onVisibilityChange}
                >
                    {
                        !this.props?.showheart ? (
                            <>
                                {
                                    (this.state.wishlistCount) > 0 && (
                                        <button className={styles.btn} style={{ background: '#1890FF', color: '#FFFFFF' }}
                                            onClick={this.onWishlistClick}>
                                            <HeartFilled style={{ paddingRight: '4px', color: '#FFFF' }} />
                                            In {this.state.wishlistCount > 1 ? this.state.wishlistCount : ''} wishlist
                                        </button>
                                    )
                                }
                                {
                                    !(this.state.wishlistCount) && (
                                        <button className={styles.btn} onClick={this.onWishlistClick}>
                                            <HeartOutlined style={{ paddingRight: '4px' }} />
                                            Add To Wishlist
                                        </button>
                                    )
                                }
                            </>
                        ) :
                            (
                                <>
                                    {
                                        (this.state.wishlistCount) > 0 && (
                                            <HeartFilled style={{ color: 'red' }} className={styles.heartIcon}
                                                onClick={this.onWishlistClick} />
                                        )
                                    }
                                    {
                                        !(this.state.wishlistCount) && (
                                            (<HeartOutlined className={styles.heartIcon}
                                                onClick={this.onWishlistClick} />)
                                        )
                                    }
                                </>
                            )
                    }

                </Popover>
            </>
        )
    }
}

export default Wishlist;