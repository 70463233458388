export const SearchParams = (params) => {
    let returnParams = undefined;

    if (params) {
        returnParams = {};
        if (params.name) {
            returnParams['name'] = params.name;
        }

        if (params.page != -1) {
            returnParams['page'] = params.page;
        }

        if (params.size != -1) {
            returnParams['size'] = params.size;
        }

        if (params.fromDate) {
            returnParams['fromDate'] = params.fromDate;
        }

        if (params.toDate) {
            returnParams['toDate'] = params.toDate;
        }

        if (params.minPropertyCount) {
            returnParams['minPropertyCount'] = +params.minPropertyCount;
        }

        if (params.maxPropertyCount) {
            returnParams['maxPropertyCount'] = +params.maxPropertyCount;
        }
    }

    return returnParams;
}
