export const marketingActions = {
  FETCH_LANGUAGE_LOADING: "FETCH_LANGUAGE_LOADING",
  FETCH_LANGUAGE_SUCCESS: "FETCH_LANGUAGE_SUCCESS",
  FETCH_LANGUAGE_FAILURE: "FETCH_LANGUAGE_FAILURE",

  SET_MARKETING_LANGUAGES: "SET_MARKETING_LANGUAGES",
  SET_OFFER_DESCRIPTION_INFO: 'SET_OFFER_DESCRIPTION_INFO',
  SET_OFFER_IMAGE_ARRAY: 'SET_OFFER_IMAGE_ARRAY',
  UPDATE_OFFER_MARKETING_INFO: 'UPDATE_OFFER_MARKETING_INFO',

  SET_OFFER_TITLE: "SET_OFFER_TITLE",
  SET_OFFER_DESCRIPTION: "SET_OFFER_DESCRIPTION",
  SET_OFFER_SHORT_DESCRIPTION: "SET_OFFER_SHORT_DESCRIPTION",
  SET_OFFER_TERMS_AND_CONDITION: "SET_OFFER_TERMS_AND_CONDITION",

  SET_PROPERTY_DESCRIPTION: "SET_PROPERTY_DESCRIPTION",
  SET_PROPERTY_SHORT_DESCRIPTION: "SET_PROPERTY_SHORT_DESCRIPTION",
  SET_PROPERTY_IMAGES: "SET_PROPERTY_IMAGES",
  RESET_REDUX_STATE: "RESET_REDUX_STATE",
  SET_LANGUAGES: "SET_LANGUAGES",
};
