import { Select } from "antd";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { supplySearchActions } from "../actions";
import styles from "./css/PropertySort.module.less";

export function PropertySort(props) {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Select span={3}
        className={styles.sltBox}
        style={{fontSize: '14px'}}
        data-testid="property-sort-order-by"
        dropdownMatchSelectWidth={false}
        defaultValue="ALL"
        onChange={(value) => {
          dispatch({
            type: supplySearchActions.SUPPLY_SEARCH_UPDATE_SORT_BY_GBL,
            payload: value,
          });
        }}
      >
       <option value="ALL"><FormattedMessage id="nemo.result.allproperties" /></option>
       <option value="NTP_SUPPLIER"><FormattedMessage id="nemo.onlyntpproperties" /></option>
      </Select>{" "}
    </Fragment>
  );
}
