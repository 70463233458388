import { createSelector } from 'reselect';

const basicSearch = (state) => state?.searchparams;

export const selectDestination = createSelector(
    [basicSearch],
    (search) => search?.destination
)

export const selectDistance = createSelector(
    [basicSearch],
    (search) => search?.distance
)
