import {Divider, Spin, Button, Menu, Input, message} from 'antd';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {getWishlist} from "../service";
import {SearchOutlined} from "@ant-design/icons";
import {searchWishlist} from "../service";
const { Search } = Input;
const InfinityComponent = (props) => {
    const { handleCancel, handleOk, property, selectedWishList } = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [wishlistID, setWishlistID] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    message.config({
        maxCount: 1
    });
    const parseServiceData = (serviceRes) => {
        if (serviceRes && serviceRes.data && serviceRes.data.result.length !== 0) {
            const serviceData = serviceRes?.data?.result
            setData(state => ([...state, ...serviceData]));
            setPage(state => (state + 1))
            const totalPagesAmount = Math.floor((serviceRes?.data?.total + 10 - 1) / 10);
            setTotalPages(totalPagesAmount)
            setLoading(false);
            totalPagesAmount > page ? setHasMore(true) : setHasMore(false)
        }
        else {
            setHasMore(false)
        }
    }
    const withoutSearchTerm = () => {
        setLoading(true);
        if ( page !== 0 && page === totalPages ) {
            setHasMore(false)
        }
        else {
            getWishlist({page: page, size: 10}).then(serviceRes => parseServiceData(serviceRes));
        }
    }
    const withSearchTerm = () => {
        setLoading(true);
        if ( page !== 0 && page === totalPages ) {
            setHasMore(false)
        }
        else {
            searchWishlist({term: searchTerm, page: page, size: 10}).then(serviceRes => parseServiceData(serviceRes));
        }
    }
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        searchTerm === "" ? withoutSearchTerm() : withSearchTerm()
    };
    const onSearchEnterHandling = () => {
        setLoading(true);
        setHasMore(true)
        setData([]);
        setPage(0)
        searchWishlist({term: searchTerm, page: 0, size: 10}).then(serviceRes => {
            if (serviceRes && serviceRes.data && serviceRes.data.result.length !== 0) {
                const serviceData = serviceRes?.data?.result
                setData(serviceData);
                const totalPagesAmount = Math.floor((serviceRes?.data?.total + 10 - 1) / 10);
                setTotalPages(totalPagesAmount)
                setLoading(false);
                totalPagesAmount > 0 ? setHasMore(true) : setHasMore(false)
            }
            else {
                setHasMore(false)
                }
        });
    }
    const searchOnChange = (event) => {
        setSearchTerm(event?.target?.value);
        if (event.target.value === "") {
            setLoading(true)
            setHasMore(true)
            setPage(0)
            setData([]);
            getWishlist({page: 0, size: 10}).then(serviceRes => parseServiceData(serviceRes));
        }
    }
    useEffect(() => {
        if(data.length === 0) {
            loadMoreData();
        }
    }, []);
    const onClickWishlistToMove = ({key}) => {
        setWishlistID(key)
    }
    const handleOkClick = () => {
        // Changing status of property in current wishlist to "NEW" in order to add.
        const wishlistToBeMoved = data.find(lst => lst.id === parseInt(wishlistID))
        const updatedProperty = [{phonebookId: property.id, wishListPropertyStatus: "NEW"}]
        const updatedNewWishlist = {...wishlistToBeMoved, properties:updatedProperty}
        typeof wishlistToBeMoved !== 'undefined' ? handleOk(updatedNewWishlist) : message.error("Please select a wishlist.");
    }
    return (
        <div>
            <div style={{ padding: '.5rem' }}>
                <Search
                    allowClear
                    value={searchTerm}
                    enterButton={
                        <Button
                            icon={<SearchOutlined onClick={onSearchEnterHandling} />}
                            onClick={() => onSearchEnterHandling}
                        />}
                    placeholder={'Search'}
                    onChange={searchOnChange}
                    onPressEnter={onSearchEnterHandling}
                />
            </div>
        <div
            id="scrollableDiv"
            style={{
                height: 200,
                overflow: 'auto',
                padding: '0 16px',
            }}
        >
            <InfiniteScroll
                dataLength={(data.filter(wish => wish.id !== selectedWishList.id)).length}
                next={loadMoreData}
                style={{overflow: "hidden"}}
                hasMore={hasMore}
                loader={
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Spin size="large"/>
                    </div>
                }
                endMessage={<Divider>No more data.</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <Menu mode="vertical" style={{border: "none"}} onSelect={onClickWishlistToMove} selectedKeys={[wishlistID]}>
                    {(data.filter(wish => wish.id !== selectedWishList.id)).map((itm) =>
                        <Menu.Item key={itm.id}>
                            {itm.name}
                        </Menu.Item>
                    )}
                </Menu>
            </InfiniteScroll>
        </div>
        <Divider/>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            <Button type="default" onClick={handleCancel} style={{margin: 5}}>Cancel</Button>
            <Button type="primary" disabled={wishlistID === ""} onClick={handleOkClick} style={{margin: 5}}>OK</Button>
        </div>
        </div>
    );
};

export default InfinityComponent;