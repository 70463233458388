import React, { Fragment, useState, useEffect } from 'react';
import { Form, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { newOfferActions } from "../../offers/actions";
import { Distance, City, Country, HotelName, Zip, Address } from '../../form';
import { SearchBtn } from './SearchBtn';
import { supplySearchActions } from "../actions/supplySearch";
import AllPropertiesDestination from "../../form/AllPropertiesDestination";

const BasicSearchAdvanced = (props) => {

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const searchParams = useSelector((state) => window.getValue(state, "searchparams"));
  const searchParamsbasic = useSelector((state) =>
    state.searchParamsBasic
  );

  useEffect(() => {
    const searchParamBasic = localStorage.getItem("advancedSearch");
    if (searchParamBasic) {
      const advancedSearch = JSON.parse(searchParamBasic);
      form.setFieldsValue(advancedSearch)
    }
  }, [])

  useState(() => {
    const searchParamBasic = localStorage.getItem("advancedSearch");
    if (searchParamBasic) {
      const advancedSearch = JSON.parse(searchParamBasic);
      form.setFieldsValue(advancedSearch)
    }

    return () => {
    }
  }, [searchParamsbasic]);

  const onSubmissionComplete = formValues => {
    if (formValues) {

      formValues['isNewSearch'] = true;
      formValues['globalSearchType'] = 'ADVANCE';
      localStorage.removeItem("basicSearch")
      localStorage.removeItem("advancedSearch");
      localStorage.setItem("advancedSearch", JSON.stringify(formValues));

      dispatch({
        type: supplySearchActions.SUPPLY_SEARCH_UPDATE_BASIC_REDUX_STATE_ALL,
        payload: formValues
      });
    }
  }

  const selection = (e) => {
    console.log(e);
  }

  return (
    <Fragment>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 25 }}
        layout="horizontal"
        onFinish={onSubmissionComplete}
        initialValues={searchParams}
        scrollToFirstError
        data-testid="basic-search-form"
        className="custom-form">

        <Row style={{ marginBottom: 35 }} gutter={[8, 8]}>
          <Col span={4} className="col1-text" style={{ fontSize: '14px' }}>
            <FormattedMessage id="nemo.search.parameters" />
          </Col>
        </Row>

        <Row style={{ marginBottom: 15 }} gutter={[8, 8]}>
          <Col span={4} className="col1-text" style={{ fontSize: '14px' }}>
            <FormattedMessage id="nemo.hotelName" />
          </Col>
          <Col span={8}>
            <Form.Item name="hotelName">
              <HotelName {...props} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginBottom: 15 }} gutter={[8, 8]}>
          <Col span={4} className="col1-text" style={{ fontSize: '14px' }}>
            <FormattedMessage id="nemo.address" />
          </Col>
          <Col span={8}>
            <Form.Item name="address">
              <Address {...props} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginBottom: 15 }} gutter={[8, 8]}>
          <Col span={4} className="col1-text" style={{ fontSize: '14px' }}>
            <FormattedMessage id="nemo.zip" />
          </Col>
          <Col span={8}>
            <Form.Item name="zip">
              <Zip {...props} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }} gutter={[8, 8]}>
          <Col span={4} className="col1-text" style={{ fontSize: '14px' }}>
            <FormattedMessage id="nemo.city" />
          </Col>
          <Col span={8}>
            <Form.Item name="city"
              rules={[
                ({
                  validator(_, value) {
                    if (value?.lat === '') {
                      return Promise.resolve();
                    } else if (value && !value?.city && !value?.lat && !value?.lng) {
                      return Promise.reject(new Error('Please select city.'));
                    } else {
                      return Promise.resolve();
                    }
                  }
                }),
              ]}>
              <City {...props} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="distance">
              <Distance distance={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }} gutter={[8, 8]}>
          <Col span={4} className="col1-text" style={{ fontSize: '14px' }}>
            <FormattedMessage id="nemo.country" />
          </Col>
          <Col span={8}>
            <Form.Item name="country"
              rules={[
                ({
                  validator(_, propVal) {
                    if (propVal?.value === '') {
                      return Promise.resolve();
                    }
                    if (propVal?.value && !propVal?.latitude && !propVal?.longitude) {
                      return Promise.reject(new Error('Please select country.'));
                    } else {
                      return Promise.resolve();
                    }
                  }
                }),
              ]}
            >
              <Country {...props} onSelect={selection} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={4} className="col1-text">
            <FormattedMessage id="nemo.search.space" />
          </Col>
          <Col span={4} >
            <SearchBtn isForSearchResultsPage={true} />
          </Col>
        </Row>
      </Form>
    </Fragment >
  )
}

export default BasicSearchAdvanced;