import React, { Fragment, useEffect, useState } from "react";
import { Form, Divider, Row, Col, Button } from "antd";
import BasicSearchAdvanced from "./BasicSearchAdvanced";
import BasicSearchSettingsResultsPage from "./BasicSearchSettingsResultsPage";
import { PropertySort } from "./PropertySort";
import { SearchedPropertyList } from "./SearchedPropertyList";
import { SearchResultPagination } from "./SearchResultPagination";
import { useSelector } from "react-redux";
import styles from "./css/SearchResultContainer.module.less";
import { DownOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";

export function SearchSettings({ submitForm, props }) {
  const [show, setPanelShow] = useState(props);
  const [form] = Form.useForm();
  const searchParams = useSelector((state) =>
    window.getValue(state, "searchparams")
  );
  const { selectedProperties } = useSelector((state) => ({
    selectedProperties: window.getValue(state, "propertycart.cartItems"),
  }));

  useEffect(() => {
    if (submitForm) {
      submitForm(form);
    }
  }, [form, submitForm]);

  const {
    properties,
    loading,
    sortBy,
  } = useSelector((state) => ({
    properties: window.getValue(state, "searchedproperties.properties"),
    loading: window.getValue(state, "searchedproperties.loading"),
    offerName: window.getValue(state, "newoffersettingsparam.name"),
    selectedProperties: window.getValue(state, "propertycart.cartItems"),
    includeMarketPrice: window.getValue(
      state,
      "marketprice.includeMarketPrice"
    ),
    sortBy: window.getValue(state, "searchparams.sortBy"),
  }));

  return (
    <Form
      form={form}
      initialValues={searchParams}
      scrollToFirstError
      className="custom-form"
      data-testid="search-setting-form">
      <dev style={{ display: "flex", justifyContent: 'flex-end' }} >
        <Button
          data-testid="basic-search-show.advance.option"
          className={styles.pos}
          type="link"
          onClick={() => {
            setPanelShow(!show);
          }}>

          {show ? (
            <FormattedMessage id="nemo.hide.advance.option" />
          ) : (
            <FormattedMessage id="nemo.advance.option" />
          )}
          <a className={"ant-dropdown-link " + styles.antDownIcon} onClick={e => e.preventDefault()}>
            <DownOutlined />
          </a>
        </Button>
      </dev>

      <Row >
        {show ? (
          <Col span={24} >
            <BasicSearchAdvanced isEditFlow={props} />
          </Col>
        ) : (
          <Col span={24} >
            <BasicSearchSettingsResultsPage isEditFlow={props} />
          </Col>
        )}
      </Row>
      <Divider />
      <Row>
        <Col span={show ? 24 : 23}>
          <Row justify="space-between" className={styles.bsHeader}>
            <Col>
              <div className={styles.searchResultHeader}>
                <h4>
                  <FormattedMessage id="nemo.search.results" />
                </h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={5} className={styles.alignLeft} >
              <PropertySort sortBy={sortBy} />
            </Col>
            <Divider type="vertical" className={styles.menuVerticalDivider} />
          </Row>
          <SearchedPropertyList
            prev={props}
            show={show}
            isEditFlow={props}
            setPanelShow={setPanelShow}
          />
        </Col>
      </Row>
      <Divider />
    </Form>
  );
}
