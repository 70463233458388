export const propertyCartActions = {
    ADD_PROPERTY_TO_CART: 'ADD_PROPERTY_TO_CART',
    DELETE_CART_ITEM: 'DELETE_CART_ITEM',
    EMPTY_CART: 'EMPTY_CART',
    UPDATE_ENTIRE_CART_LIST: 'UPDATE_ENTIRE_CART_LIST',
    DISCARD_CHANGES_IN_EDITED_CART_LIST: "DISCARD_CHANGES_IN_EDITED_CART_LIST",
    UPDATE_LANGUAGE_WITH_DESCRIPTION_IN_PROPERTY: 'UPDATE_LANGUAGE_WITH_DESCRIPTION_IN_PROPERTY',

    UPDATE_SHORT_DESCRIPTION_FOR_CART_ITEM: "UPDATE_SHORT_DESCRIPTION_FOR_CART_ITEM",
    UPDATE_DESCRIPTION_FOR_CART_ITEM: "UPDATE_DESCRIPTION_FOR_CART_ITEM",
    UPDATE_IMAGES_FOR_CART_ITEM: "UPDATE_IMAGES_FOR_CART_ITEM",
    UPDATE_CART_CHANGE_STATUS: "UPDATE_CART_CHANGE_STATUS",
  }