import React from 'react';
import { Modal } from 'antd';

import { deleteWishlist } from '../service'

const WishlistDeleteConfirmModal = (props) => {

    const { isModalVisible, setIsModalVisible, wishlistProp, onRefresh } = props;

    const handleOk = () => {
        if (wishlistProp) {
            deleteWishlist(wishlistProp.id).then(serviceRes => {
                if (serviceRes) {
                    setIsModalVisible(false);
                    onRefresh(true);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    return (
        <Modal title="Do you want to remove these properties?" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <h4>{wishlistProp?.propertyCount} selected will be removed from "{wishlistProp?.name}" wishlist.</h4>
        </Modal>
    )
}

export default WishlistDeleteConfirmModal;