import { browseSupplyActions, supplySearchActions, wishlistActions } from "./actions";
import { store } from "./../../redux/store";
import { globalActions } from "../../redux/globalActions";
import { baseURL } from "../../helpers";
import { createSearchQueryURL, getSearchParamsToFetchMarketPrice } from "../../helpers/utility";
import { offerListSearchAndFilterActions } from "../offers/actions";
import { stringify, parse } from 'query-string';

export const parseQuery = (query) => {
  return parse(query);
}

export const getQueryString = () => {
  const basicSearch = JSON.parse(localStorage.getItem("basicSearch"));
  const advancedSearch = JSON.parse(localStorage.getItem('advancedSearch'));

  if (basicSearch) {
    return stringify({
      city: basicSearch?.destination?.city,
      lat: basicSearch?.destination?.lat,
      lng: basicSearch?.destination?.lng,
      distance: basicSearch?.distance,
    });
  }

  if (advancedSearch) {
    return stringify({
      hotelName: advancedSearch?.hotelName,
      address: advancedSearch?.address,
      zip: advancedSearch?.zip,
      city: advancedSearch?.city?.city,
      cityLat: advancedSearch?.city?.lat,
      cityLng: advancedSearch?.city?.lng,
      distance: advancedSearch?.distance,
      countryValue: advancedSearch?.country?.value,
      countryCode: advancedSearch?.country?.code,
      countryLongitude: advancedSearch?.country?.longitude,
      countryLatitude: advancedSearch?.country?.latitude,
      isNewSearch: advancedSearch?.isNewSearch,
      globalSearchType: advancedSearch?.globalSearchType
    });
  }
}

export function getBasicSerachServerUrl(params, pageOffset, pageSize) {
  const searchParams = {
    distance: params?.distance,
    globalSearchType: params?.globalSearchType,
    latitude: params?.latitude,
    longitude: params?.longitude,
    pageOffset: pageOffset,
    pageSize: pageSize,
    propertyGroupType: params?.propertyGroupType
  }
  return stringify(searchParams);
}

export function getAdvancedSearchServerUrl(params, pageOffset, pageSize) {
  const cityPayload = params?.city;
  const countryPayload = params?.countryCode;

  const searchParams = {
    address: params.Address,
    city: cityPayload?.city?.split(",")[0],
    latitude: cityPayload?.lat,
    longitude: cityPayload?.lng,
    countryCode: countryPayload?.code,
    distance: params.distance,
    hotelName: params.hotelName,
    zip: params.zip,
    globalSearchType: params.globalSearchType,
    pageOffset: pageOffset,
    pageSize: pageSize,
    propertyGroupType: params?.propertyGroupType
  }
  return stringify(searchParams);
}

export const searchSupply = (routePostSearch, isEditFlow) => {
  store.dispatch({
    type: browseSupplyActions.BROWSE_SUPPLY_SEARCH_LOADING,
    payload: true,
  });
  store.dispatch({
    type: supplySearchActions.SET_INCLUDE_MARKET_PRICE_FLAG,
    payload: false,
  });

  window
    .fetchWrapper(`${baseURL.API_URL_BASE}search/` + createSearchQueryURL(routePostSearch))
    .then((response) => {
      if (response.success) {
        store.dispatch({
          type: browseSupplyActions.BROWSE_SUPPLY_SEARCH_SUCCESS,
          payload: window.getValue(response, "data.properties"),
          totalMatch: window.getValue(response, "data.totalMatchedProperties"),
          pageOffset: window.getValue(response, "data.pageOffset"),
          pageSize: window.getValue(response, "data.pageSize"),
        });
      } else {
        store.dispatch({
          type: browseSupplyActions.BROWSE_SUPPLY_SEARCH_FAILURE,
          payload: window.getValue(response, "error.apierror.message"),
        });
      }
    })
    .catch((error) => {
      store.dispatch({
        type: browseSupplyActions.BROWSE_SUPPLY_SEARCH_FAILURE,
        payload: error.toString(),
      });
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: error.toString(),
      });
    });
};

export const checkAndAssignWishlist = (wishlistPayload) => {
  return window.putWrapper(`${baseURL.API_URL_BASE}wishlists/properties`, JSON.stringify(wishlistPayload));
}

export const saveWishlist = (wishlistPayload) => {
  return window.postWrapper(`${baseURL.API_URL_BASE}wishlists`, wishlistPayload)
}

export const searchWishlist = (paramName, page = 0) => {
  if (paramName) {
    const param = {
      name: paramName,
      page: page,
      size: 10
    }
    const queryString = stringify(param);

    window.fetchWrapper(`${baseURL.API_URL_BASE}wishlists?${queryString}`).then(serviceRes => {
      if (serviceRes) {
        store.dispatch({
          type: wishlistActions.FETCH_WISHLIST,
          payload: serviceRes,
        });
      }
    })
  } else {
    const param = {
      page: page,
      size: 10
    }
    const queryString = stringify(param);
    window.fetchWrapper(`${baseURL.API_URL_BASE}wishlists?${queryString}`).then(serviceRes => {
      if (serviceRes) {
        store.dispatch({
          type: wishlistActions.FETCH_WISHLIST,
          payload: serviceRes,
        });
      }
    })
  }
}

export const searchFromQuery = (query) => {
  return window.fetchWrapper(`${query}`);
}

export const searchGlobalProperties = (queryString) => {
  localStorage.setItem('search-url', `${queryString}`);
  return window.fetchWrapper(`${baseURL.API_URL_BASE}global/properties?${queryString}`);
}

export const advancedSearch = (
  params,
  searchType,
  page,
  pageSize,
  sortBy,
  order,
  groupType
) => {
  return window.fetchWrapper(`${baseURL.API_URL_BASE}global/properties?address=${params.address}&city=${params.city}&countryCode=${params.countryCode}&distance=${params.distance}&hotelName=${params.hotelName}&latitude=${params.latitude}&longitude=${params.longitude}&zip=${params.zip}&pageOffset=${page}&pageSize=${pageSize}&propertyGroupType=${groupType}&sortCriteria=${sortBy}&sortOrder=${order}&globalSearchType=${searchType}`);
}

export const globalSearchResult = (params) => {

  window
    .fetchWrapper(`/global/properties?address=${params.address}`)
    .then((response) => {
      console.log(response)
    })
    .catch((error) => {
      store.dispatch({
        type: supplySearchActions.SEARCH_CHANNELS_FAILURE,
        payload: [],
      });
      return [];
    });
}

export const getChannels = () => {
  store.dispatch({
    type: supplySearchActions.SEARCH_CHANNELS_LOADING,
    payload: true,
  });
  window
    .fetchWrapper(`${baseURL.API_URL_BASE}portals`)
    .then((response) => {
      if (response.success) {
        store.dispatch({
          type: supplySearchActions.SEARCH_CHANNELS_SUCCESS,
          payload: window.getValue(response, "data.result"),
        });
      } else {
        store.dispatch({
          type: supplySearchActions.SEARCH_CHANNELS_FAILURE,
          payload: [],
        });
      }
    })
    .catch((error) => {
      store.dispatch({
        type: supplySearchActions.SEARCH_CHANNELS_FAILURE,
        payload: [],
      });
      return [];
    });
};

export const getBrands = async () => {
  store.dispatch({
    type: supplySearchActions.SEARCH_BRANDS_LOADING,
    payload: true,
  });
  window
    .fetchWrapper(`${baseURL.API_URL_BASE}sources`)
    .then((response) => {
      if (response.success) {
        store.dispatch({
          type: supplySearchActions.SEARCH_BRANDS_SUCCESS,
          payload: window.getValue(response, "data.brands"),
        });
        store.dispatch({
          type: offerListSearchAndFilterActions.UPDATE_OFFER_SELECTORS,
          payload: window.getValue(response, "data.brands"),
        });
      } else {
        store.dispatch({
          type: supplySearchActions.SEARCH_BRANDS_FAILURE,
          payload: [],
        });
      }
      return window.getValue(response, "data.brands");
    })
    .catch((error) => {
      store.dispatch({
        type: supplySearchActions.SEARCH_BRANDS_FAILURE,
        payload: [],
      });
      return [];
    });
};

export const getCurrencies = async () => {
  store.dispatch({
    type: supplySearchActions.FETCH_CURRENCIES_LOADING,
    payload: true,
  });
  window
    .fetchWrapper(`${baseURL.API_URL_BASE}currencies`)
    .then((response) => {
      if (response.success) {
        store.dispatch({
          type: supplySearchActions.FETCH_CURRENCIES_SUCCESS,
          payload: window.getValue(response, "data.currencies"),
        });
      } else {
        store.dispatch({
          type: supplySearchActions.FETCH_CURRENCIES_FAILURE,
          payload: [],
        });
      }
    })
    .catch((error) => {
      store.dispatch({
        type: supplySearchActions.FETCH_CURRENCIES_FAILURE,
        payload: [],
      });
      return [];
    });
};

export const getMarketPricesForListOfProperties = () => {
  store.dispatch({
    type: supplySearchActions.FETCH_MARKET_PRICE_LOADING,
    payload: true,
  });

  window
    .fetchWrapper(
      `${baseURL.API_URL_BASE}search/marketprice/` +
      getSearchParamsToFetchMarketPrice()
    )
    .then((response) => {
      if (response.success) {
        store.dispatch({
          type: supplySearchActions.FETCH_MARKET_PRICE_SUCCESS,
          payload: window.getValue(response, "data"),
        });
      } else {
        store.dispatch({
          type: supplySearchActions.FETCH_MARKET_PRICE_FAILURE,
          payload: [],
        });
      }
    })
    .catch((error) => {
      store.dispatch({
        type: supplySearchActions.FETCH_MARKET_PRICE_FAILURE,
        payload: [],
      });
      return [];
    });
};
