import React, { Fragment } from 'react';
import {Modal, message, Button} from 'antd';
import InfinityComponent from "./infinityComponent";
import { removePropertyFromWishlist, movePropertyFromWishlist } from '../service'

const WishListItemActionsModal = (props) => {
    message.config({
        maxCount: 1
    });
    const { IsActionsModalVisible, setIsActionsModalVisible, actionModalKey, onRefresh, property, selectedWishlist } = props;

    const handleOk = (updatedWishlist) => {
        /* Need to improve the code. 
        Wishlist related work should
        be move to actions and data to redux store*/

        if (actionModalKey === "1") {
            // Call delete endpoint
            const param = {
                wishlists: [
                    {
                        id: selectedWishlist.id,
                        name: selectedWishlist.name,
                        properties: [
                            {
                                name: property.name,
                                phonebookId: property.id,
                                wishListPropertyStatus: "DELETE"
                            }
                        ],
                        searchUrl: selectedWishlist.searchUrl
                    }
                ]
            }
            setIsActionsModalVisible(false);
            removePropertyFromWishlist(param).then(res => {
                if (res && res.success) {
                    onRefresh();
                    message.success("Success.");
                }
                else {
                    message.error(res.error.message);
                }
            }).catch(error => {
                message.error(error);
            });
        }
        else {
            // Call Move endpoint
            // Changing status of property in current wishlist to "DELETE" in order to remove.
            const updatedListCurrentWishlist = selectedWishlist.properties.reduce((acc, current) => {
                if(current.phonebookId === property.id) {
                    return acc.concat({...current,wishListPropertyStatus: "DELETE"})
                }
                else {
                    return acc
                }
            }, [])
            const updatedCurrentWishlist = {...selectedWishlist, properties:updatedListCurrentWishlist}

            const param = {
                wishlists: [
                    updatedCurrentWishlist,
                    updatedWishlist
                ]
            }
            movePropertyFromWishlist(param).then(res => {
                 if (res && res.success) {
                     setIsActionsModalVisible(false);
                     onRefresh();
                     message.success("Success.");
                 }
                 else {
                     message.error(res.error.message);
                 }
             }).catch(error => {
                message.error(error);
            });
        }
    }
    const handleCancel = () => {
        setIsActionsModalVisible(false);
    }
    const RemoveComponentUI = (wishlist) => {
        return(
            <Fragment>
                <h4>Selected property will removed from wishlist.</h4>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button type="default" onClick={handleCancel} style={{margin: 5}}>Cancel</Button>
                    <Button type="primary" onClick={handleOk} style={{margin: 5}}>OK</Button>
                </div>
            </Fragment>
        )
    }
    const MoveComponentUI = (propValue) => {
        return(
            <Fragment>
                <InfinityComponent selectedWishList={selectedWishlist} handleCancel={handleCancel} handleOk={handleOk} property={property}/>
            </Fragment>
        )
    }
    const ModalBodyComponent = (propValue) => {
        return(
            <Fragment>
                {actionModalKey === "1" ? <RemoveComponentUI/> : <MoveComponentUI/> }
            </Fragment>
        )
    }

    return (
        <Modal footer={null} title={actionModalKey === "1" ? "Do you want to remove these properties?" : "Move selected properties to another list?"} visible={IsActionsModalVisible} onCancel={handleCancel}>
            <ModalBodyComponent/>
        </Modal>
    )
}

export default WishListItemActionsModal;