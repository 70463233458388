import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Spin } from 'antd';
import styles from "./css/SearchedPropertyList.module.less";
import emptyImage from '../../../assets/images/no-img.svg'

const ImageViewer = (props) => {
    const { dataItem } = props;
    const [validImages, setValidIMages] = useState([]);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const propertyList = dataItem?.knownIds?.map(x => x.property);
        if (propertyList && propertyList.length > 0) {
            const infoMappedResult = propertyList.filter(x => x && x.info)?.map(x => x.info);
            if (infoMappedResult && infoMappedResult.length > 0) {
                setImageLoaded(false);
                const imageList = infoMappedResult[0]?.images;
                if (imageList && imageList.length > 0) {

                    const img = imageList[0];
                    axios.get(img).then(res => {
                        if (res?.status === 200) {
                            validImages.push({ img: img, status: res?.status });
                            const timeOutRef = setTimeout(() => {
                                setImageLoaded(true);
                                clearTimeout(timeOutRef);
                            }, 1000);
                        } else {
                            setImageLoaded(true);
                        }
                    }).catch(error => {
                        console.log(error);
                        setImageLoaded(true);
                    })
                } else {
                    setImageLoaded(true);
                }
            } else {
                setImageLoaded(true);
            }
        }

        return () => {
            setValidIMages([]);
            setImageLoaded(false);
        }
    }, [dataItem])

    return (
        <Fragment>
            {
                imageLoaded ?
                    (
                        <Fragment>
                            {
                                validImages && validImages.length > 0 ? (
                                    <img src={validImages[0]?.img} alt="hotel-image" width="121" />
                                ) : (
                                    <div className={styles.emptyImage}>
                                        <img src={emptyImage} alt="empty-image" />
                                    </div>
                                )
                            }
                        </Fragment>
                    ) :
                    (
                        <Spin tip="Loading..." />
                    )
            }
        </Fragment>
    )
}

export default ImageViewer;