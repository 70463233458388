import React, { Fragment } from 'react';
import { Menu } from "antd";

const WishListMenu = (props) => {

    const { onClick, menuItem, hide } = props;

    return (
        <Fragment>
            <Menu onClick={(e) => onClick(e, menuItem)}>
                {
                    (!hide || hide === 'false') && (
                        <Menu.Item style={{ fontSize: '14px' }} key="2" >
                            Last search request
                        </Menu.Item>
                    )
                }
                <Menu.Item style={{ fontSize: '14px' }} key="3" >
                    Rename
                </Menu.Item>
                <Menu.Item style={{ fontSize: '14px' }} key="4" >
                    Download XLS file
                </Menu.Item>
                <Menu.Item style={{ fontSize: '14px' }} key="5" >
                    Delete
                </Menu.Item>
            </Menu>
        </Fragment>
    )
}
export default WishListMenu;
