import React, { useEffect, useCallback, useState } from "react";
import { Form, Row, Col } from "antd";
import AllPropertiesDestination from "../../form/AllPropertiesDestination";
import Distance from "../../form/Distance";
import { SearchBtn } from "./SearchBtn";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import styles from "./css/SearchResultContainer.module.less";
import { supplySearchActions } from "../actions/supplySearch";
import { newOfferActions } from "../../offers/actions";
import { selectDestination, selectDistance } from '../reducers/basic-search.selector';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const BasicSearchSettingsResultsPage = ({ submitForm }) => {

  const { state } = useLocation();
  const dispatch = useDispatch();

  const searchParams = useSelector((state) =>
    window.getValue(state, "searchparams")
  );

  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values) => {
      localStorage.removeItem("basicSearch");
      localStorage.removeItem('advancedSearch');
      localStorage.setItem('basicSearch', JSON.stringify(values));

      dispatch({
        type: supplySearchActions.SUPPLY_SEARCH_UPDATE_BASIC_SETTING_REDUX_STATE,
        payload: {
          distance: values.distance,
          lat: values.destination.lat,
          lng: values.destination.lng ? values.destination.lng : values.destination.lan,
          isNewSearch: true,
          city: values.destination.city,
          destination: {
            city: values.destination.city,
            lat: values.destination.lat,
            lng: values.destination.lng ? values.destination.lng : values.destination.lan,
          }
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (submitForm) {
      submitForm(form);
    }

  }, [state, form, submitForm]);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={searchParams}
      scrollToFirstError
      onFinishFailed={onFinishFailed}
      className={"custom-form " + styles.customForm}
      data-testid="search-setting-form"
    >

      <Row gutter={[9, 1]} style
        layout="inline">
        <Col span={7}>
          <Form.Item name="destination" data-testid="destination" rules={[
            { required: true, message: 'Please select destination.' },
            ({
              validator(_, value) {
                if (value && !value.lat && !value.lng) {
                  return Promise.reject(new Error('Please select destination.'));
                } else {
                  return Promise.resolve();
                }
              }
            }),
          ]}>
            <AllPropertiesDestination
              onChangeDestination={(destination) => {
                dispatch({
                  type: supplySearchActions.SUPPLY_SEARCH_UPDATE_DESTINATION,
                  payload: destination,
                });
                dispatch({
                  type: newOfferActions.NEW_OFFER_DEEPLINK_DESTINATION,
                  payload: destination,
                });

                if (!destination) {
                  dispatch({
                    type: supplySearchActions.SUPPLY_SEARCH_UPDATE_BASIC_SETTING_REDUX_STATE,
                    payload: {},
                  });
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="distance" data-testid="distance">
            <Distance
              onChangeDistance={(distance) => {
                dispatch({
                  type: supplySearchActions.SUPPLY_SEARCH_UPDATE_DISTANCE,
                  payload: distance,
                });
              }}
              distance={0}
            />
          </Form.Item>
        </Col>
        <Col span={2}  >
          <Form.Item >
            <SearchBtn

              layout="inline" isForSearchResultsPage={true} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = createStructuredSelector({
  destination: selectDestination,
  distance: selectDistance,
});

export default connect(mapStateToProps, null)(BasicSearchSettingsResultsPage);
