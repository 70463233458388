export const supplySearchActions = {
  SUPPLY_SEARCH_UPDATE_REDUX_STATE: "SUPPLY_SEARCH_UPDATE_REDUX_STATE",

  SUPPLY_SEARCH_UPDATE_DESTINATION: "SUPPLY_SEARCH_UPDATE_DESTINATION",
  SUPPLY_SEARCH_UPDATE_DISTANCE: "SUPPLY_SEARCH_UPDATE_DISTANCE",
  SUPPLY_SEARCH_UPDATE_LOS: "SUPPLY_SEARCH_UPDATE_LOS",
  SUPPLY_SEARCH_UPDATE_HOTEL_NAME: "SUPPLY_SEARCH_UPDATE_HOTEL_NAME",
  SUPPLY_SEARCH_UPDATE_DATE_RANGE: "SUPPLY_SEARCH_UPDATE_DATE_RANGE",
  SUPPLY_SEARCH_ADD_BRAND: "SUPPLY_SEARCH_ADD_BRAND",
  SUPPLY_SEARCH_UPDATE_BRANDS: "SUPPLY_SEARCH_UPDATE_BRANDS",
  SUPPLY_SEARCH_UPDATE_SUPPLIERS: "SUPPLY_SEARCH_UPDATE_SUPPLIERS",
  SUPPLY_SEARCH_UPDATE_CHANNELS: "SUPPLY_SEARCH_UPDATE_CHANNELS",
  SUPPLY_SEARCH_UPDATE_IS_SUPPLIER: "SUPPLY_SEARCH_UPDATE_IS_SUPPLIER",
  SUPPLY_SEARCH_UPDATE_LCN_FLAG: "SUPPLY_SEARCH_UPDATE_LCN_FLAG",
  SUPPLY_SEARCH_UPDATE_REMAINING_CAPITAL_POOL:
    "SUPPLY_SEARCH_UPDATE_REMAINING_CAPITAL_POOL",
  SUPPLY_SEARCH_UPDATE_CURRENCY: "SUPPLY_SEARCH_UPDATE_CURRENCY",
  SUPPLY_SEARCH_UPDATE_COUNTRY: "SUPPLY_SEARCH_UPDATE_COUNTRY",
  SUPPLY_SEARCH_UPDATE_SORT_BY: "SUPPLY_SEARCH_UPDATE_SORT_BY",

  SUPPLY_SEARCH_UPDATE_PAGE_OFFSET: "SUPPLY_SEARCH_UPDATE_PAGE_OFFSET",
  SUPPLY_SEARCH_UPDATE_PAGE_SIZE: "SUPPLY_SEARCH_UPDATE_PAGE_SIZE",

  SEARCH_CHANNELS_LOADING: "SEARCH_CHANNELS_LOADING",
  SEARCH_CHANNELS_SUCCESS: "SEARCH_CHANNELS_SUCCESS",
  SEARCH_CHANNELS_FAILURE: "SEARCH_CHANNELS_FAILURE",

  SEARCH_BRANDS_LOADING: "SEARCH_BRANDS_LOADING",
  SEARCH_BRANDS_SUCCESS: "SEARCH_BRANDS_SUCCESS",
  SEARCH_BRANDS_FAILURE: "SEARCH_BRANDS_FAILURE",

  SEARCH_ADD_BRAND: "SEARCH_ADD_BRAND",
  SEARCH_UPDATE_BRAND: "SEARCH_UPDATE_BRAND",
  SEARCH_REMOVE_BRAND: "SEARCH_REMOVE_BRAND",

  SEARCH_UPDATE_SUPPLIER: "SEARCH_UPDATE_SUPPLIER",
  SEARCH_REMOVE_SUPPLIER: "SEARCH_REMOVE_SUPPLIER",

  SEARCH_UPDATE_STOREFRONT: "SEARCH_UPDATE_STOREFRONT",
  SEARCH_REMOVE_STOREFRONT: "SEARCH_REMOVE_STOREFRONT",
  SEARCH_ADD_ROW_STOREFRONT: "SEARCH_ADD_ROW_STOREFRONT",

  SEARCH_UPDATE_CHANNEL: "SEARCH_UPDATE_CHANNEL",
  SEARCH_REMOVE_CHANNEL: "SEARCH_REMOVE_CHANNEL",

  RESET_SEARCH_PARAM_STATE: "RESET_SEARCH_PARAM_STATE",

  FETCH_CURRENCIES_LOADING: "FETCH_CURRENCIES_LOADING",
  FETCH_CURRENCIES_SUCCESS: "FETCH_CURRENCIES_SUCCESS",
  FETCH_CURRENCIES_FAILURE: "FETCH_CURRENCIES_FAILURE",

  SET_SELECTED_CURRENCY: "SET_SELECTED_CURRENCY",

  FETCH_MARKET_PRICE_LOADING: "FETCH_MARKET_PRICE_LOADING",
  FETCH_MARKET_PRICE_SUCCESS: "FETCH_MARKET_PRICE_SUCCESS",
  FETCH_MARKET_PRICE_FAILURE: "FETCH_MARKET_PRICE_FAILURE",
  SET_INCLUDE_MARKET_PRICE_FLAG: "SET_INCLUDE_MARKET_PRICE_FLAG",

  SUPPLY_SEARCH_UPDATE_PROPERTY_TYPE: "SUPPLY_SEARCH_UPDATE_PROPERTY_TYPE",
};
