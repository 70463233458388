import React, { useEffect, useState, Fragment } from 'react';
import { Col, Row, Dropdown, Tooltip, Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { searchWishlist } from '../../basicSearch/service';
import styles from './css/LastSearchItem.module.less';
import star from '../../../assets/images/start.svg';
import WishlistSearchMenuAction from './WishlistSearchMenuAction';
import ImageViewer from '../../basicSearch/components/ImageViewer';
import WishListItemActionsModal from './WishListItemActionsModal';


const WishlistSearchItem = (props) => {

    const { property, onRefresh, selectedWishlist } = props;
    const [IsActionsModalVisible, setIsActionsModalVisible] = useState(false);
    const [actionModalKey, setActionModalKey] = useState("1");

    const onMenuActionChange = (e, menuItem) => {
        setIsActionsModalVisible(true);
        setActionModalKey(e.key)
    }

    const menuOverLay = (propValue) => {
        return(
            <Fragment>
                <WishlistSearchMenuAction menuItem={propValue} onClick={onMenuActionChange}/>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <WishListItemActionsModal
                IsActionsModalVisible={IsActionsModalVisible}
                setIsActionsModalVisible={setIsActionsModalVisible}
                actionModalKey={actionModalKey}
                property={property}
                selectedWishlist={selectedWishlist}
                onRefresh={onRefresh}
            />
            <Row>
                <div className={styles.imgwrap}>
                    <ImageViewer dataItem={property} />
                </div>
                <Col span={10} style={{ padding: 14 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span className={styles.property}>
                            {property?.name}
                        </span>
                    </div>
                    <span style={{ fontSize: 12 }}>
                        {property?.address}, {property?.city}, {property?.country} {property?.zip}
                    </span>
                </Col>
                <Col span={5}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10, alignItems: 'center' }}>
                        <span style={{ color: '#8C8C8C', fontSize: '12px' }}>Star rating</span>
                        <span style={{ display: 'flex', flexDirection: 'row', fontSize: '14px' }}>
                            {
                                property.rateCount > 0 && (
                                    <span>
                                        {[...Array(property.rateCount)].map((element, index) => {
                                            return <img key={index} src={star} alt={element} />
                                        })}
                                    </span>)
                            }
                            {
                                property.rateCount === 0 && 'N/A'
                            }
                        </span>
                    </div>
                </Col>

                <Col span={5}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10, alignItems: 'start' }}>
                        <span style={{ color: '#8C8C8C', textAlign: 'center', fontSize: 12 }}>Supplier</span>
                        <span style={{ paddingTop: 8, fontSize: '14px' }}>
                            {
                                property.suppliers?.length > 0 && (
                                    <span>
                                        {
                                            property.suppliers[0]?.toString()?.length > 6 && (
                                                <Tooltip title={property.suppliers[0]}>
                                                    <span> {property.suppliers[0].toString().substring(0, 6) + "..."}</span>
                                                </Tooltip>
                                            )
                                        }
                                        {
                                            property.suppliers[0]?.toString()?.length <= 6 && (
                                                <span> {property.suppliers[0].toString()}</span>
                                            )
                                        }
                                        {
                                            property.knownIds.length >= 2 && ", "
                                        }
                                        {
                                            property.suppliers[1]?.toString()?.length > 6 && (
                                                <Tooltip title={property.suppliers[1]}>
                                                    <span> {property.suppliers[1].toString().substring(0, 6) + "..."}</span>
                                                </Tooltip>
                                            )
                                        }
                                        {
                                            property.suppliers[1]?.toString()?.length <= 6 && (
                                                <span> {property.suppliers[1].toString()}</span>
                                            )
                                        }
                                    </span>
                                )
                            }
                            {
                                property.knownIds.length > 2 ? (
                                    <span> +{property.knownIds.length - 2}
                                        <Popover className={styles.moreOption} content={
                                            property.suppliers.map((supplier, index) =>
                                                <div key={index} className={styles.supplierListItem}>➡️ {supplier}</div>
                                            )
                                        } title="View Suppliers">
                                            <span style={{ fontSize: '14px' }}>more</span>
                                        </Popover></span>) : null
                            }
                        </span>
                    </div>
                </Col>
                <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                    <div>
                    <Dropdown overlay={menuOverLay(property)}>
                      <MoreOutlined
                        style={{ color: '#096DD9' }}
                        className={styles.moreIcon}
                      />
                    </Dropdown>
                    </div>
                </div>
            </Row>
        </Fragment>
    )
}

export default WishlistSearchItem;
