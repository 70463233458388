export const offerListSearchAndFilterActions = {
  UPDATE_OFFER_SELECTORS: "UPDATE_OFFER_SELECTORS",
  APPLY_OFFER_LIST_FILTERS: "APPLY_OFFER_LIST_FILTERS",
  APPLY_SEARCH_KEY: "APPLY_SEARCH_KEY",

  OFFER_LIST_LOADING: "OFFER_LIST_LOADING",
  OFFER_LIST_SUCCESS: "OFFER_LIST_SUCCESS",
  OFFER_LIST_FAILURE: "OFFER_LIST_FAILURE",
  OFFER_LIST_PAGE_OFFSET: "OFFER_LIST_PAGE_OFFSET",
  OFFER_LIST_PAGE_SIZE: "OFFER_LIST_PAGE_SIZE",
  RESET_OFFERS_PAGINATION: "RESET_OFFERS_PAGINATION",
  SELECTED_TAB_KEY: "SELECTED_TAB_KEY",

  SET_OFFER_FILTER_DATA: "SET_OFFER_FILTER_DATA",
  RESET_OFFER_FILTER_DATA: "RESET_OFFER_FILTER_DATA",

  OFFER_DETAILS_LOADING: "OFFER_DETAILS_LOADING",
  OFFER_DETAILS_SUCCESS: "OFFER_DETAILS_SUCCESS",
  OFFER_DETAILS_FAILURE: "OFFER_DETAILS_FAILURE",
};
