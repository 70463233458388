export const offerImageSearchAndFilterActions = {
    UPDATE_OFFER_SELECTORS: "UPDATE_OFFER_SELECTORS",
    APPLY_OFFER_IMAGE_FILTERS: "APPLY_OFFER_IMAGE_FILTERS",
    APPLY_SEARCH_KEY: "APPLY_SEARCH_KEY",
  
    OFFER_IMAGE_LOADING: "OFFER_IMAGE_LOADING",
    OFFER_IMAGE_SUCCESS: "OFFER_IMAGE_SUCCESS",
    OFFER_IMAGE_FAILURE: "OFFER_IMAGE_FAILURE",
    OFFER_IMAGE_LIST_PAGE_OFFSET: "OFFER_IMAGE_LIST_PAGE_OFFSET",
    OFFER_IMAGE_LIST_PAGE_SIZE: "OFFER_IMAGE_LIST_PAGE_SIZE",
    RESET_OFFER_IMAGE_PAGINATION: "RESET_OFFER_IMAGE_PAGINATION",
    RESET_OFFERS_PAGINATION: "RESET_OFFERS_PAGINATION",
    SET_OFFER_IMAGE_FILTER_DATA: "SET_OFFER_IMAGE_FILTER_DATA",
    APPLY_OFFER_IMAGE_INITIAL_STATE: "APPLY_OFFER_IMAGE_INITIAL_STATE",
  };
  