import React from "react";
import { Select } from "antd";
import { useIntl } from "react-intl";
import { appConstants } from "../../common";

function PropertyType(props) {
  const { showOnlyProd } = props;
  const intl = useIntl();
  return (
    <React.Fragment>
      <Select
        value={props.value}
        placeholder={intl.formatMessage({ id: "nemo.select" })}
        data-testid="property-type-select"
        onChange={(value) => {
          if (props.onChange) {
            props.onChange(value);
          }
          if (props.onChangeType) {
            props.onChangeType(value);
          }
        }}
      >
        {
          showOnlyProd ? (
            <Select.Option
              value={appConstants.PROPERTY_TYPE_LIST[1].id}
              key={appConstants.PROPERTY_TYPE_LIST[1].id}
              data-testid={"property-type-select-" + appConstants.PROPERTY_TYPE_LIST[1].id}>
              {appConstants.PROPERTY_TYPE_LIST[1].name}
            </Select.Option>
          ) : (
            appConstants.PROPERTY_TYPE_LIST.map((propertyType) => (
              <Select.Option value={propertyType.id} key={propertyType.id} data-testid={"property-type-select-" + propertyType.id}>
                {propertyType.name}
              </Select.Option>
            ))
          )
        }
      </Select>
    </React.Fragment>
  );
}

export default PropertyType;
