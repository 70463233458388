import React, { Fragment, useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Collapse, Badge, Input, DatePicker } from "antd";
import { FilterFilled } from "@ant-design/icons";
import styles from "./css/WishlistFilters.module.less";
import Modal from "antd/lib/modal/Modal";
import moment from "moment/min/moment-with-locales";

const { Panel } = Collapse;
const dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;
const numberExp = /[^0-9]/g;

const WishListFilters = (props) => {

  const { filteredCount, onFiltrationHandler } = props;
  const [visible, setVisible] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [minProperty, setMinProperty] = useState();
  const [maxProperty, setMaxProperty] = useState();
  const [rangeDates, setRangeDates] = useState();

  useEffect(() => {

    return () => {
      resetFeilds();
    }
  }, []);

  const onDateRangeChange = (dates) => {
    if (dates) {
      const fromDate = moment(dates[0]).format("YYYY-MM-DD");
      const toDate = moment(dates[1]).format("YYYY-MM-DD");
      setFromDate(fromDate);
      setToDate(toDate);
    } else {
      setFromDate(null);
      setToDate(null);
    }
    setRangeDates(dates);
  }

  const cancelModal = () => {
    setVisible(false);
  };

  const resetFeilds = () => {
    setFromDate(null);
    setToDate(null);
    setMinProperty();
    setMaxProperty();
  }

  const onResetHandler = () => {
    resetFeilds();
    setRangeDates(() => [null, null]);
    onFiltrationHandler();
  }

  const onApplyHandler = () => {
    const params = {
      fromDate: fromDate,
      toDate: toDate,
      minProperty: minProperty,
      maxProperty: maxProperty,
    }
    onFiltrationHandler(params);
    setVisible(false);
  }

  return (
    <Fragment>
      <div
        className={styles.badgeContainer}
        onClick={() => {
          setVisible(true);
        }}
      >
        <Badge
          count={filteredCount}
          offset={[-5, 5]}
          data-testid="wish-list-filter-badge"
          className={styles.badgeFilter}
        >
          <Button
            type="text"
            data-testid="wish-list-filter-ic-btn"
            icon={<FilterFilled size={20} className="filter-ic" />}
          >
          </Button>
        </Badge>
      </div>
      <Modal
        title={
          <React.Fragment>
            <FormattedMessage id="nemo.filterBy" />
          </React.Fragment>
        }
        onCancel={cancelModal}
        visible={visible}
        closable={true}
        destroyOnClose={true}
        forceRender={true}
        okText={<FormattedMessage id="nemo.yes" />}
        cancelText={<FormattedMessage id="nemo.cancel" />}
        footer={[
          <Button
            key="cancel"
            type="text"
            onClick={cancelModal}
            data-testid="wish-list-cancel-btn"
          >
            <FormattedMessage id="nemo.cancel" />
          </Button>,
          <Button
            key="reset"
            type="text"
            onClick={onResetHandler}
          >
            <FormattedMessage id="nemo.reset" />
          </Button>,
          <Button
            key="submit"
            type="link"
            onClick={onApplyHandler}
          >
            <FormattedMessage id="nemo.apply" />
          </Button>,
        ]}
      >
        <Collapse isOpened={false}>
          <Panel
            header="Wishlist creation date"
            key="Wishlist-creation-date"
            className={styles["collapse-font"]}
          >
            <Fragment>
              <div
                className="wish-list-view-field-name"
              >
                <RangePicker
                  allowClear={true}
                  value={rangeDates}
                  format={dateFormat}
                  onChange={onDateRangeChange}
                />
              </div>
            </Fragment>
          </Panel>
          <Panel
            header="Number of properties"
            key="wishlists"
            className={styles["collapse-font"]}
          >
            <Fragment>
              <Input
                placeholder="Min Property Count"
                size="large"
                style={{ width: "100px" }}
                onChange={(event) => setMinProperty(event?.target?.value?.replace(numberExp, ''))}
                value={minProperty}
              />
            </Fragment>
            -:-
            <Fragment>
              <Input
                placeholder="Max Property Count"
                size="large"
                style={{ width: "100px" }}
                onChange={(event) => setMaxProperty(event?.target?.value?.replace(numberExp, ''))}
                value={maxProperty}
              />
            </Fragment>
          </Panel>
        </Collapse>
      </Modal>
    </Fragment>
  );
}

export default WishListFilters;