import produce from "immer";
import { wishlistActions } from '../actions';

const initialState = {
    wishlistDataSet: [],
    isSaved: false
}

export const wishlistReducer = produce(
    (draft, action) => {
        switch (action.type) {
            case wishlistActions.FETCH_WISHLIST:
                draft.wishlistDataSet = action?.payload?.data.result;
                draft.wishlistDataSet.total = action?.payload?.data.total;
                return;
            case wishlistActions.SAVE_WISHLIST:
                draft.isSaved = true
                return;
            default:
                return draft
        }
    },
    initialState
);
