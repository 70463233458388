import React from "react";
import { Row, Col } from "antd";

import { FormattedMessage } from "react-intl";

export function WishlistHeader() { 
  return (
    <React.Fragment>
      <Row justify="space-between" >
        <Col>
          <h2 style={{fontSize: '24px'}}>
            <FormattedMessage id="nemo.wishlist" />
          </h2>
        </Col>
      </Row>
     
    </React.Fragment>
  );
}
