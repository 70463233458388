export const newOfferActions = {
  NEW_OFFER_UPDATE_REDUX_STATE: "NEW_OFFER_UPDATE_REDUX_STATE",
  NEW_OFFER_RESET_REDUX_STATE: "NEW_OFFER_RESET_REDUX_STATE",
  INITIAL_ADD_BRANDS: 'INITIAL_ADD_BRANDS',
  NEW_OFFER_UPDATE_CHANNELS: "NEW_OFFER_UPDATE_CHANNELS",
  NEW_OFFER_UPDATE_SUPPLIERS: "NEW_OFFER_UPDATE_SUPPLIERS",
  NEW_OFFER_REMOVE_SUPPLIER: "NEW_OFFER_REMOVE_SUPPLIER",
  NEW_OFFER_REMOVE_CHANNEL: "NEW_OFFER_REMOVE_CHANNEL",
  NEW_OFFER_ADD_ROW_STOREFRONT: "NEW_OFFER_ADD_ROW_STOREFRONT",
  NEW_OFFER_ADD_STOREFRONT: "NEW_OFFER_ADD_STOREFRONT",
  NEW_OFFER_UPDATE_STOREFRONT: "NEW_OFFER_UPDATE_STOREFRONT",
  NEW_OFFER_UPDATE_OFFER_NAME: "NEW_OFFER_UPDATE_OFFER_NAME",
  NEW_OFFER_UPDATE_OFFER_ID: "NEW_OFFER_UPDATE_OFFER_ID",
  NEW_OFFER_UPDATE_STATUS: "NEW_OFFER_UPDATE_STATUS",
  NEW_OFFER_REMOVE_STOREFRONT: "NEW_OFFER_REMOVE_STOREFRONT",
  NEW_OFFER_REMOVE_BRAND: "NEW_OFFER_REMOVE_BRAND",
  NEW_OFFER_ADD_NEW_BRAND: "NEW_OFFER_ADD_NEW_BRAND",
  NEW_OFFER_UPDATE_BRAND: "NEW_OFFER_UPDATE_BRAND",

  NEW_OFFER_DEEPLINK_LOS: 'NEW_OFFER_DEEPLINK_LOS',
  NEW_OFFER_DEEPLINK_CHECK_IN_TYPE: "NEW_OFFER_DEEPLINK_CHECK_IN_TYPE",
  NEW_OFFER_DEEPLINK_ROLLING_DAYS: 'NEW_OFFER_DEEPLINK_ROLLING_DAYS',
  NEW_OFFER_DEEPLINK_CHECK_IN_FIXED_DATE: "NEW_OFFER_DEEPLINK_CHECK_IN_FIXED_DATE",
  NEW_OFFER_DEEPLINK_SPECIFIC_HOTEL: "NEW_OFFER_DEEPLINK_SPECIFIC_HOTEL",
  NEW_OFFER_DEEPLINK_DESTINATION: "NEW_OFFER_DEEPLINK_DESTINATION",
  NEW_OFFER_DEEPLINK_CHILD_OCCUPANCY: "NEW_OFFER_DEEPLINK_CHILD_OCCUPANCY",
  NEW_OFFER_DEEPLINK_ADULT_OCCUPANCY: "NEW_OFFER_DEEPLINK_ADULT_OCCUPANCY",
  
  NEW_OFFER_DEEPLINK_INCLUDE_ALL_PROPERTIES: "NEW_OFFER_DEEPLINK_INCLUDE_ALL_PROPERTIES",

  NEW_OFFER_TRAVELLING_DATE_RANGE: "NEW_OFFER_TRAVELLING_DATE_RANGE",
  NEW_OFFER_BOOKING_DATE_RANGE: "NEW_OFFER_BOOKING_DATE_RANGE",
  NEW_OFFER_BOOKING_TIME_ZONE: "NEW_OFFER_BOOKING_TIME_ZONE",

  CREATE_NEW_OFFER_LOADING: 'CREATE_NEW_OFFER_LOADING',
  CREATE_NEW_OFFER_SUCCESS: 'CREATE_NEW_OFFER_SUCCESS',
  CREATE_NEW_OFFER_FAILURE: 'CREATE_NEW_OFFER_FAILURE',

  NEW_OFFER_LAST_SEARCH_DISTANCE: "NEW_OFFER_LAST_SEARCH_DISTANCE",
  NEW_OFFER_SET_OFFER_PROPERTY_TYPE: "NEW_OFFER_SET_OFFER_PROPERTY_TYPE",
};
