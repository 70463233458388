import React, { Fragment } from 'react';
import { Menu } from 'antd';

const WishlistSearchMenuAction = (props) => {

    const { onClick, menuItem } = props;
    return (
        <Fragment>
            <Menu onClick={(e) => onClick(e, menuItem)}>
                <Menu.Item style={{ fontSize: '14px' }} key="1" >
                    Remove
                </Menu.Item>
                <Menu.Item style={{ fontSize: '14px' }} key="2" >
                    Move
                </Menu.Item>
            </Menu>
        </Fragment>
    )
}

export default WishlistSearchMenuAction;